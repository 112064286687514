import React from "react";
import Envolope from "./../../../images/Vector (1).png";

function Mail() {
  return (
    <div className="">
      <div className="main-container mb-[130px]">
        <div className="bg-red md:px-[59px] px-9 pt-[36px] pb-[58px] ">
          <div className="pb-[30px]">
            <img src={Envolope} alt="" />
          </div>
          <p className="text-white">Johnrellite@mail.com</p>
        </div>
      </div>
    </div>
  );
}

export default Mail;
