import React, { useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
const Faq = ({ questionNumber, question, answer }) => {
  const [open, setOpen] = useState(0);
  const handleCollapse = (id) => {
    if (open === id) {
      setOpen(!id);
    } else {
      setOpen(id);
    }
  };
  return (
    <div>
      <div className="w-full px-[50px]">
        <div className="main-container bg-greylight ">
          <div className="">
            <div className="mb-5">
              <div key={questionNumber}>
                <div
                  className={`py-[21px]  w-full border-b border-gray  cursor-pointer flex justify-between  `}
                  onClick={() => handleCollapse(questionNumber)}
                >
                  <div
                    className={` ${
                      open === questionNumber
                        ? "  transition-all duration-700 ease-in-out "
                        : " transition-all  duration-700 ease-in-out"
                    } text-2xl  font-normal cursor-pointer  text-black`}
                  >
                    Q&nbsp;{questionNumber}:&nbsp;{question}
                  </div>
                  <div className=" text-2xl mr-4">
                    {open === questionNumber ? (
                      <AiOutlineMinus />
                    ) : (
                      <AiOutlinePlus />
                    )}
                  </div>
                </div>
                <div
                  className={`${
                    open === questionNumber ? "open" : "collapse"
                  } bg-transparent   `}
                >
                  <p
                    className="pb-[50px] pt-[10px] text-xl font-normal"
                    style={{ color: "#000" }}
                  >
                    {answer}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;

const questions = [
  {
    id: 1,
    question: "Q 1: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    answer:
      "A: Lorem ipsum dolor sit amet, consectetur adipiscing elit. At tincidunt nulla ut arcu cras duis nisl eu nec. Bibendum euismod mollis vel, a, vestibulum rhoncus. Etiam scelerisque pretium accumsan, dolor in. Mauris faucibus malesuada enim sed.",
  },
  {
    id: 2,
    question: "Q 2: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    answer:
      "A: Lorem ipsum dolor sit amet, consectetur adipiscing elit. At tincidunt nulla ut arcu cras duis nisl eu nec. Bibendum euismod mollis vel, a, vestibulum rhoncus. Etiam scelerisque pretium accumsan, dolor in. Mauris faucibus malesuada enim sed.",
  },
  {
    id: 3,
    question: "Q 3: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    answer:
      "A: Lorem ipsum dolor sit amet, consectetur adipiscing elit. At tincidunt nulla ut arcu cras duis nisl eu nec. Bibendum euismod mollis vel, a, vestibulum rhoncus. Etiam scelerisque pretium accumsan, dolor in. Mauris faucibus malesuada enim sed.",
  },
  {
    id: 4,
    question: "Q 4: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    answer:
      "A: Lorem ipsum dolor sit amet, consectetur adipiscing elit. At tincidunt nulla ut arcu cras duis nisl eu nec. Bibendum euismod mollis vel, a, vestibulum rhoncus. Etiam scelerisque pretium accumsan, dolor in. Mauris faucibus malesuada enim sed.",
  },
];
