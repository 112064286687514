import React, { useState, useEffect, useContext } from "react";
import voucher from "../../../images/voucher1.png";
import Button from "../../ModuleComponents/Button";
import { getallSettings } from "../../../api/api";
import parse from "html-react-parser";
import { toast } from "react-toastify";
import ToastContext from "../../../context/ToastContext";
const Vouchers = () => {
  const [About, setAbout] = useState("");

  const toastOptions = useContext(ToastContext);

  useEffect(() => {
    getsetting();
  }, []);

  const getsetting = () => {
    getallSettings().then((res) => {
      setAbout(res.data.data.about_us);
    });
  };

  return (
    <div className="py-10">
      <div className="main-container">
        {parse(About)}
        {/* <div className="flex flex-col gap-y-[50px]">
          <div className="flex md:flex-row flex-col gap-y-8 gap-x-[100px]">
            <div className="max-w-[1212px] p-[30px] border-[3px] border-primary">
              <img src={voucher} alt="voucher" />
            </div>
            <div className="flex justify-center flex-1 items-center">
              <Button
                className={`w-full sm:w-[270px] lg:w-[309px]`}
                height={66}
              >
                Get It!
              </Button>
            </div>
          </div>
          <div className="flex md:flex-row flex-col gap-y-8 gap-x-[100px]">
            <div className="max-w-[1212px] md:order-last p-[30px] border-[3px] border-primary">
              <img src={voucher} alt="voucher" />
            </div>
            <div className="flex justify-center flex-1 items-center">
              <Button
                className={`w-full sm:w-[270px] lg:w-[309px]`}
                height={66}
              >
                Get It!
              </Button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Vouchers;
