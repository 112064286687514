import React, { useState, useEffect, useContext } from "react";
import Card from "./Card";
import gucci from "../../images/gucci.png";
import vougue from "../../images/vougue.png";
import brickell from "../../images/brickell.png";
import { Link } from "react-router-dom";
import { getallBrand } from "../../api/api";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

const Filter = ({ setId, Bid }) => {
  const [brand, setBrand] = useState();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    brandData();
  }, []);
  const brandData = () => {
    getallBrand()
      .then((res) => {
        setBrand(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleBrand = (e) => {
    if (Bid.filter((i) => i === e)[0] === e) {
      setId(Bid.filter((i) => i !== e));
    } else {
      setId([...Bid, e]);
    }
  };

  return (
    <div className="flex flex-col gap-y-5">
      {brand?.map((e) => (
        <div className="flex items-center gap-x-4">
          <input
            type={`checkbox`}
            name="checkbox"
            value={e._id}
            className="w-[22px] h-[22px]"
            onChange={() => handleBrand(e._id)}
          />
          <span className="text-xl capitalize">{e.name}</span>
        </div>
      ))}
    </div>
  );
};

export default Filter;
