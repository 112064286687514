import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import b1 from "../../../images/b1.png";
import b2 from "../../../images/b2.png";
import b3 from "../../../images/b3.png";
import b4 from "../../../images/b4.png";
import { Link, useNavigate } from "react-router-dom";
import { getallBrand } from "../../../api/api";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

const ShopByBrands = () => {
  const [brand, setBrand] = useState();
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    brandData();
  }, []);
  const brandData = () => {
    getallBrand()
      .then((res) => {
        setBrand(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  SwiperCore.use([Autoplay, Navigation]);

  const sliderResponsive = {
    1300: {
      slidesPerView: 5,
    },
    1024: {
      slidesPerView: 4,
    },
    768: {
      slidesPerView: 3,
    },
    640: {
      slidesPerView: 2,
    },
  };
  return (
    <>
      {isLoading ? (
        <Stack
          sx={{ color: "grey.500", marginLeft: "45%" }}
          spacing={2}
          direction="row"
        >
          <CircularProgress color="success" />
        </Stack>
      ) : (
        <>
          <div className="pt-[70px] pb-10">
            <div className="main-container">
              <h3 className="section-title mb-[50px]">Shop By Brands</h3>
              <div className="w-full Special">
                <Swiper
                  // loop="true"
                  slidesPerView={1}
                  breakpoints={sliderResponsive}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                  pagination
                  navigation
                >
                  {brand.map((Card) => {
                    return (
                      <SwiperSlide
                        className="flex justify-center relative"
                        key={Card._id}
                      >
                        <img
                          className="w-[200px] h-[200px] img-responsive"
                          src={Card.Image}
                          alt=""
                        />
                        <div
                          className="flex justify-center items-center max-w-[336px] h-[44px] absolute bottom-5 inset-x-0 mx-auto bg-white bg-opacity-70 font-bold text-[20px] uppercase text-primary"
                          onClick={() =>
                            navigate(`/productList/${Card._id}`, {
                              state: Card._id,
                            })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {Card.name}
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ShopByBrands;
