import React from "react";
import vector1 from "../../../images/black-vector.png";
import vector2 from "../../../images/pink-vector.png";
import Button from "../../ModuleComponents/Button";

const Verification = ({ setIndex }) => {
  return (
    <div>
      <div className="relative">
        <img className="top-0 relative" src={vector1} alt="vector" />
        <img className="-top-3 lg:-top-5 relative" src={vector2} alt="vector" />
      </div>

      <div className="py-[50px] px-4">
        <h3 className="text-center mx-auto max-w-[308px] capitalize font-bold text-xl lg:text-2xl text-primary pb-[25px] border-b border-[#999999]">
          Verification
        </h3>

        <div className="mt-[114px] mb-[126px] flex flex-col max-w-[440px] mx-auto w-full">
          <span className="text-xl font-bold text-primary text-center mb-8">
            00 : 00
          </span>

          <form className="grid grid-cols-4 gap-x-[30px]">
            <input
              required
              className="text-center text-[30px] text-primary h-[50px] w-full  border-gray border-b focus:outline-none"
            ></input>

            <input
              required
              className="text-center text-[30px] text-primary h-[50px] w-full  border-gray border-b focus:outline-none"
            ></input>

            <input
              required
              className="text-center text-[30px] text-primary h-[50px] w-full  border-gray border-b focus:outline-none"
            ></input>

            <input
              required
              className="text-center text-[30px] text-primary h-[50px] w-full  border-gray border-b focus:outline-none"
            ></input>
          </form>
        </div>

        <div className="flex flex-col">
          <Button
            type={`submit`}
            width={308}
            secondary
            onClick={() => setIndex(5)}
            className={`mb-[30px] lg:h-[70px] h-[60px] mx-auto`}
          >
            send again
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Verification;
