import React, { useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { MyImage } from "./MyImage";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const OrderHistory = ({ data }) => {
  const [type, setType] = useState("all orders");

  const [Deliver, setDelivered] = useState(
    data?.filter((e) => e.orderStatus === "Delivered")
  );
  const [Receiv, setReceived] = useState(
    data?.filter((e) => e.orderStatus === "Received")
  );
  const [SHIPPEDORDERS, setSHIPPEDORDERS] = useState(
    data?.filter((e) => e.orderStatus === "Order Have Been Send to Courier")
  );
  const [PENDINGORDERS, setPENDINGORDERS] = useState(
    data?.filter((e) => e.orderStatus === "Pending")
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage -
            (type === "all orders"
              ? data?.length
              : type === "Pending"
              ? PENDINGORDERS?.length
              : type === "Shipped"
              ? SHIPPEDORDERS?.length
              : type === "Delivered"
              ? Deliver?.length
              : type === "Received"
              ? Receiv?.length
              : 0)
        )
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //   const filteredCards = games.filter((game) => game.type.includes(type));

  const handleType = (type) => {
    setType(type);
  };
  let navigate = useNavigate();

  return (
    <div className="max-w-[1392px] w-full px-5 mx-auto">
      <div className="flex flex-col">
        <ul className="flex flex-wrap gap-x-[60px] gap-y-2">
          {orderTypes.map((btn) => (
            <li
              key={btn.id}
              onClick={() => handleType(btn.type)}
              className={`${
                btn.type === type ? "text-primary border-b" : "text-gray"
              } inline-flex pb-[10px] capitalize border-primary  text-[30px] font-bold  cursor-pointer `}
            >
              {btn.type}
            </li>
          ))}
        </ul>
        <div className="">
          <div className="min-w-max">
            <div className="flex items-center border-y mt-[50px] border-gray py-3">
              <div className="flex-1 flex">
                <div className="flex-[2] flex gap-x-[40px] ">
                  <div className="flex-1">
                    <p className="text-gray text-xl capitalize">item</p>
                  </div>
                  <div className="flex-[2]">
                    <p className="text-gray text-xl capitalize">detail</p>
                  </div>{" "}
                </div>
              </div>
              <div className="flex-[2.4] flex">
                <div className="flex-1 flex justify-center">
                  <p className="text-xl text-gray capitalize "> Variant </p>{" "}
                </div>
                <div className="flex-1 flex justify-center">
                  <p className="text-xl text-gray capitalize"> quantity </p>{" "}
                </div>
                <div className="flex-1 flex justify-center">
                  <p className="text-xl text-gray capitalize"> price </p>{" "}
                </div>
                <div className="flex-1 flex justify-center">
                  <p className="text-xl text-gray capitalize"> Shipping Fee </p>{" "}
                </div>
                <div className="flex-1 flex justify-center">
                  <p className="text-xl text-gray capitalize"> Order </p>{" "}
                </div>
                <div className="flex-1 flex justify-center">
                  <p className="text-xl text-gray capitalize"> Date </p>{" "}
                </div>
                <div className="flex-1 flex justify-center">
                  <p className="text-xl text-gray capitalize"> Action </p>{" "}
                </div>
              </div>
            </div>

            {(type === "all orders"
              ? rowsPerPage > 0
                ? data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : data
              : type === "Pending"
              ? rowsPerPage > 0
                ? PENDINGORDERS.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : PENDINGORDERS
              : type === "Shipped"
              ? rowsPerPage > 0
                ? SHIPPEDORDERS.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : SHIPPEDORDERS
              : type === "Delivered"
              ? rowsPerPage > 0
                ? Deliver.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : Deliver
              : type === "Received"
              ? rowsPerPage > 0
                ? Receiv.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : Receiv
              : []
            )?.map((e) => {
              return (
                <div className="flex items-center mt-[25px] ">
                  <div className="flex-1 flex">
                    <div className="flex-[2] flex gap-x-[40px] ">
                      <div className="flex-1">
                        <p className="text-black text-xl capitalize">
                          {" "}
                          <MyImage
                            dimension={100}
                            borderRadius="0%"
                            src={e?.orderItem[0]?.product_image}
                          />
                        </p>
                      </div>
                      <div className="flex-[2]">
                        <p className="text-black text-xl capitalize">
                          <div className="flex-[2] flex flex-col gap-y-4">
                            <h3 className="text-primary capitalize font-bold text-lg">
                              {e?.orderItem[0]?.name}
                            </h3>
                            <p className="text-lg text-primary">
                              {e?.orderItem[0]?.description.substring(0, 25)}
                              <br></br>
                              {e?.orderItem[0]?.description.substring(25, 30)}
                              ......
                            </p>
                          </div>
                        </p>
                      </div>{" "}
                    </div>
                  </div>
                  <div className="flex-[2.3] flex">
                    <div className="flex-1 flex justify-center">
                      <p className="text-lg text-black capitalize">
                        {" "}
                        <div>{e?.orderItem[0]?.V1}</div>
                        <div>{e?.orderItem[0]?.V2}</div>{" "}
                      </p>{" "}
                    </div>
                    <div className="flex-1 flex justify-center">
                      <p className="text-lg text-black capitalize">
                        {" "}
                        {e?.orderItem[0]?.Qty}{" "}
                      </p>{" "}
                    </div>
                    <div className="flex-1 flex justify-center">
                      <p className="text-lg text-black capitalize">
                        {" "}
                        ${e?.totalPrice}{" "}
                      </p>{" "}
                    </div>
                    <div className="flex-1 flex justify-center">
                      <p className="text-lg text-black capitalize">
                        {" "}
                        ${e?.shippingPrice}{" "}
                      </p>{" "}
                    </div>
                    <div className="flex-1 flex justify-center">
                      <p className="text-lg text-black capitalize">
                        {" "}
                        {e?.orderStatus == "Order Have Been Send to Courier"
                          ? "Shipped"
                          : e?.orderStatus}{" "}
                      </p>{" "}
                    </div>
                    <div className="flex-1 flex justify-center">
                      <p className="text-lg text-black capitalize">
                        {" "}
                        {moment(e?.createdAt).format("YYYY-MM-DD")}{" "}
                      </p>{" "}
                    </div>
                    <div className="flex-1 flex justify-center">
                      <p className="text-lg text-black capitalize">
                        {" "}
                        <RemoveRedEyeIcon
                          fontSize="large"
                          sx={{ color: "#ff6969", cursor: "pointer" }}
                          onClick={() =>
                            navigate("/order-details", { state: e })
                          }
                        />{" "}
                      </p>{" "}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={
            type === "all orders"
              ? data.length
              : type === "Pending"
              ? PENDINGORDERS.length
              : type === "Shipped"
              ? SHIPPEDORDERS.length
              : type === "Delivered"
              ? Deliver.length
              : type === "Received"
              ? Receiv.length
              : 0
          }
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </div>
    </div>
  );
};

export default OrderHistory;
const orderTypes = [
  { id: 1, type: "all orders" },
  { id: 2, type: "Pending" },
  { id: 3, type: "Shipped" },
  { id: 4, type: "Delivered" },
  { id: 5, type: "Received" },
];
