import React from "react";
//          Importing Colors
import { DarkColor, TileBackgroundColor } from "../../../assets/Colors";

export const FaqCategory = ({
  Icon,
  children,
  isSelected,
  onClick,
  marginRight,
}) => {
  const Style = {
    container: {
      maxWidth: "250px",
      maxHeight: "200px",
      minWidth: "250px",
      minHeight: "200px",
      color: isSelected ? "#FFFFFF" : DarkColor,
      cursor: "pointer",
      backgroundColor: isSelected ? DarkColor : TileBackgroundColor,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginRight: `${marginRight ? marginRight : 0}px`,
    },
    iconContainer: {
      marginTop: "39px",
    },
    icon: {
      width: "48px",
      height: "48px",
    },
    text: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "24px",
      lineHeight: "27px",
      marginTop: "47px",
    },
  };
  return (
    <div onClick={onClick ? onClick : () => {}}>
      {isSelected ? (
        <a>
          <div className="flex  gap-y-[47px]  w-full px-4  max-h-[200px]  text-white bg-dark py-[39px]  flex-col items-center it-selected">
            <img src={Icon} alt="" width="48" height="48" />
            <p className="text-2xl text-center font-normal">{children}</p>
          </div>
        </a>
      ) : (
        <a>
          <div className="flex hover:bg-primary gap-y-[47px]  w-full px-4  max-h-[200px]  hover:text-white text-primary bg-greylight py-[39px]  it-selected flex-col items-center">
            <img src={Icon} alt="" width="48" height="48" />
            <p className="text-2xl text-center font-normal">{children}</p>
          </div>
        </a>
      )}
    </div>
  );
};
