import React, { useContext } from "react";
import { Link } from "react-router-dom";
import vector1 from "../../../images/black-vector.png";
import vector2 from "../../../images/pink-vector.png";
import Button from "../../ModuleComponents/Button";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { userlogin } from "../../../api/api";
import { toast } from "react-toastify";
import ToastContext from "../../../context/ToastContext";
const MyAccount = ({ setIndex, setOpen }) => {
  const toastOptions = useContext(ToastContext);
  let navigate = useNavigate();
  return (
    <div>
      <div className="relative">
        <img className="top-0 relative" src={vector1} alt="vector" />
        <img className="-top-3 lg:-top-5 relative" src={vector2} alt="vector" />
      </div>

      <div className="py-[50px] px-4">
        <h3 className="text-center mx-auto max-w-[308px] capitalize font-bold text-xl lg:text-2xl text-primary pb-[25px] border-b border-[#999999]">
          my account
        </h3>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
            password: Yup.string().max(255).required("Password is required"),
          })}
          onSubmit={(values) => {
            userlogin(values)
              .then((res) => {
                localStorage.setItem(
                  "auth_user",
                  JSON.stringify({
                    user: res.data.user,
                  })
                );
                localStorage.setItem(
                  "token",
                  JSON.stringify({
                    token: res.data.token,
                  })
                );
                localStorage.setItem(
                  "auth_user_type",
                  JSON.stringify({
                    type: res.data.user.usertype,
                  })
                );

                window.location.reload();
                toast.success(res.data.message, toastOptions);
              })
              .catch((err) => {
                if (err.response.status === 401) {
                  setOpen();
                  toast.error(err.response.data.error, toastOptions);
                }
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="mt-[102px] mb-[126px] flex flex-col max-w-[440px] mx-auto w-full">
                <>
                  <input
                    type={`email`}
                    placeholder="Email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    className="px-[13px] text-primary h-[50px] w-full  border-gray border focus:outline-none  py-[11px]"
                  ></input>
                  {touched.email && errors.email && (
                    <text
                      style={{ color: "red" }}
                      error
                      id="standard-weight-helper-text-email-login"
                    >
                      {errors.email}
                    </text>
                  )}
                  <input
                    type={`password`}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    placeholder="Password"
                    className="px-[13px] text-primary h-[50px] w-full mt-[25px] border-gray border focus:outline-none  py-[11px]"
                  ></input>
                  {touched.password && errors.password && (
                    <text
                      style={{ color: "red" }}
                      error
                      id="standard-weight-helper-text-password-login"
                    >
                      {errors.password}
                    </text>
                  )}
                </>
                <Link
                  onClick={() => setIndex(3)}
                  to={`/`}
                  className="text-primary text-lg lg:text-xl font-light mt-[10px]"
                >
                  Forgotten Password?
                </Link>
              </div>

              <div className="flex flex-col">
                <Button
                  type={`submit`}
                  width={308}
                  height={70}
                  className={`mb-[30px] mx-auto`}
                >
                  Sign In
                </Button>
              </div>
            </form>
          )}
        </Formik>
        <div className="flex flex-col">
          <p className="text-center text-primary font-light text-xl ">
            If you don’t have registered with us,
            <br />
            <Link onClick={() => setIndex(2)} className="text-red-500" to="/">
              Registration
            </Link>{" "}
            will be requested.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
