import React, { useState } from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import img1 from "../../../images/product/img1.png";
import img2 from "../../../images/product/img2.png";
import img3 from "../../../images/product/img3.png";
import { AiOutlineStar, AiTwotoneStar } from "react-icons/ai";
import Rating from "react-rating";
import Button from "../../ModuleComponents/Button";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { Link } from "react-router-dom";
import { addcart } from "../../../api/api";
import { useNavigate } from "react-router-dom";
import { MyImage } from "./MyImage";
import { Grid } from "@mui/material";

const ProductDetail = ({ data }) => {
  let navigate = useNavigate();
  const [path, setpath] = useState(img1);
  const [wishlist, setWishlist] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [sizes, setSizes] = useState(data?.Variant1?.type[0]?.text);
  const [colors, setColor] = useState(data?.Variant2?.type[0]?.text);
  const [focus, setFocus] = useState(data?.product_image[0]?.img);
  const handlesize = (size) => {
    setSizes(size);
  };
  const handlecolor = (color) => {
    setColor(color);
  };
  const handleIncremant = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleWishlist = () => {
    setWishlist(!wishlist);
  };
  const handlePath = (p) => {
    setpath(p);
  };
  let currentuser = JSON.parse(localStorage.getItem("auth_user"));
  const addtocard = (e) => {
    addcart(e).then((res) => {
      if (res.data.status === true) {
        navigate("/cart");
      }
    });
  };
  let arr = [];

  data?.reviews?.map((e) => arr.push(e.review));

  const average = arr?.reduce((a, b) => a + b, 0) / arr?.length;
  let price = data?.productVariant?.filter(
    (e) => e.V1Tag === sizes && e.V2Tag === colors
  );

  let weight = data?.productVariant?.filter(
    (e) => e.V1Tag === sizes && e.V2Tag === colors
  );
  return (
    <div className="pb-[50px] pt-8">
      <div className="main-container">
        <div className="grid gap-x-8 2xl:gap-x-[75px] gap-y-16 xl:grid-cols-[minmax(53%,_1fr)_1fr]">
          <div className="flex gap-x-[30px]">
            <ul className="flex flex-1 shrink flex-col gap-y-[23px]">
              {data?.product_image?.map((e) => {
                return (
                  <li
                    className="cursor-pointer"
                    onClick={() => setFocus(e.img)}
                    key={e.img}
                  >
                    <MyImage dimension={100} borderRadius="0%" src={e.img} />
                  </li>
                );
              })}
            </ul>
            <div className="flex-[5]">
              <img width="750px" height="500px" alt="product" src={focus} />
            </div>
          </div>

          <div className="p-[30px] max-w-[630px]">
            <div className="flex flex-col">
              <div className="flex justify-between items-center mb-[26px]">
                <h4 className="text-[36px] font-bold text-primary">
                  {data.name}
                </h4>

                <span
                  onClick={handleWishlist}
                  className="text-4xl cursor-pointer"
                >
                  {wishlist ? <AiFillHeart /> : <AiOutlineHeart />}
                </span>
              </div>

              <p className="text-3xl text-primary mb-[25px] ">
                {data.description}
              </p>

              <form>
                <label className="text-2xl font-bold " htmlFor="">
                  {data?.Variant1?.name} :
                </label>

                <ul className="flex-[1] gap-x-[10px] w-[650px] ml-[-10px] mt-[10px]">
                  {data?.Variant1?.type?.map((li) => {
                    return (
                      <li
                        onClick={() => handlesize(li.text)}
                        key={li.id}
                        className={`${
                          sizes === li.text
                            ? "bg-selected"
                            : "border-none bg-gray"
                        } uppercase cursor-pointer bg-gray text-xl inline-flex justify-center items-center mb-5 ml-2`}
                      >
                        <text style={{ padding: "5px" }}>{li.text}</text>
                      </li>
                    );
                  })}
                </ul>
              </form>

              <form>
                <label className="text-2xl font-bold">
                  {data?.Variant2?.name} :
                </label>
                <ul className="flex gap-x-[10px] mt-4">
                  {data?.Variant2?.type?.map((li) => {
                    return (
                      <li
                        onClick={() => handlecolor(li.text)}
                        key={li.id}
                        className={`${
                          colors === li.text
                            ? "bg-selected"
                            : "border-none bg-gray"
                        } uppercase cursor-pointer  text-xl inline-flex justify-center items-center mb-4`}
                      >
                        <text style={{ padding: "5px" }}>{li.text}</text>
                      </li>
                    );
                  })}
                </ul>
              </form>
              <div>
                <h4 className="text-2xl font-bold capitalize mb-4">
                  Weight : {!weight[0]?.weight ? "0" : weight[0]?.weight} kg
                </h4>
              </div>

              <div>
                <h4 className="text-2xl font-bold capitalize mb-4">price :</h4>
                <span className="font-bold text-5xl">
                  $
                  {!price[0]?.Price
                    ? (data?.productVariant[0].Price)
                    : (price[0]?.Price)}
                </span>
              </div>

              <div className="flex justify-between sm:flex-row flex-col gap-y-8 sm:items-center mt-[25px]">
                <div>
                  <h4 className="text-2xl font-bold capitalize mb-4">
                    rating :
                  </h4>
                  <Rating
                    initialRating={average}
                    readonly
                    emptySymbol={
                      <div className="text-2xl 2xl:text-4xl">
                        <AiOutlineStar />
                      </div>
                    }
                    fullSymbol={
                      <div className="text-2xl 2xl:text-4xl text-[#FFB801]">
                        <AiTwotoneStar />
                      </div>
                    }
                  />
                </div>

                <div>
                  <h4 className="text-2xl font-bold capitalize mb-4">
                    quantity :
                  </h4>

                  <div className="w-[218px] border-2 border-primary h-[54px] flex items-center justify-between">
                    <button
                      onClick={handleDecrement}
                      className="flex-1 text-4xl font-bold text-primary px-5"
                    >
                      -
                    </button>
                    <div className="max-w-max flex justify-center">
                      <input
                        value={quantity}
                        className="w-full text-center focus:outline-none text-2xl justify-center flex items-center"
                      ></input>
                    </div>
                    <button
                      onClick={handleIncremant}
                      className="flex-1 text-4xl font-bold text-primary px-5"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex justify-between sm:flex-row flex-col items-center gap-y-8 gap-x-[40px] mt-[50px]">
                <Button
                  height={66}
                  secondary
                  className={`sm:w-[309px]`}
                  onClick={() => {
                    addtocard({
                      id: data?._id,
                      namd: data?.name,
                      des: data?.description,
                      weight: data?.productVariant[0]?.weight,
                      qty: quantity,
                      img: data?.product_image[0].img,
                      price: !price[0]?.Price
                        ? data?.productVariant[0].Price
                        : price[0]?.Price,
                      v1: `${data?.productVariant[0]?.V1}: ${sizes}`,
                      v2: `${data?.productVariant[0]?.V2}: ${colors}`,
                      user_id: currentuser.user._id,
                    });
                  }}
                >
                  <span className="mr-2  text-primary text-2xl">
                    <HiOutlineShoppingBag />
                  </span>
                  add to cart
                </Button>

                <Button
                  height={66}
                  className={`sm:w-[208px]`}
                  onClick={() =>
                    navigate("/payment", {
                      state: {
                        cart: {
                          id: data?._id,
                          namd: data?.name,
                          description: data?.description,
                          weight: data?.productVariant[0]?.weight,
                          Qty: quantity,
                          product_image: data?.product_image[0].img,
                          price: !price[0]?.Price
                            ? data?.productVariant[0].Price
                            : price[0]?.Price,
                          V1: `${data?.productVariant[0]?.V1}: ${sizes}`,
                          V2: `${data?.productVariant[0]?.V2}: ${colors}`,
                          user_id: currentuser.user._id,
                        },
                        step: 2,
                        subTotal: (!price[0]?.Price
                          ? data?.productVariant[0].Price
                          : price[0]?.Price * quantity
                        ).toFixed(2),
                      },
                    })
                  }
                >
                  Buy Now
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
