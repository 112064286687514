import React from "react";
import { AiOutlineStar, AiTwotoneStar } from "react-icons/ai";
import Rating from "react-rating";
import moment from "moment";
const Reviews = ({ data }) => {
  let arr = [];

  data?.reviews?.map((e) => arr.push(e.review));

  const average = arr?.reduce((a, b) => a + b, 0) / arr?.length;

  return (
    <div className="py-16">
      <div className="main-container">
        <div className="flex flex-col gap-y-[50px]">
          <div className="flex flex-col gap-y-8 md:flex-row gap-x-5 pb-[50px] border-b border-gray">
            <div className="flex-1 flex sm:flex-row flex-col gap-y-4 gap-x-4">
              <div className="flex-1 flex flex-col gap-y-[25px]">
                <h3 className="text-5xl font-light"></h3>
                <Rating
                  initialRating={average}
                  readonly
                  emptySymbol={
                    <div className="text-2xl 2xl:text-4xl">
                      <AiOutlineStar />
                    </div>
                  }
                  fullSymbol={
                    <div className="text-2xl 2xl:text-4xl text-[#FFB801]">
                      <AiTwotoneStar />
                    </div>
                  }
                />
              </div>
              <div className="flex-1 flex items-end">
                <h3 className="font-bold text-[30px] text-primary"></h3>
              </div>
            </div>
            <div className="flex-1 flex md:justify-end items-end">
              <div className="flex items-center gap-x-3">
                <span className="text-gray font-bold text-[24px] capitalize">
                  sort by
                </span>
                <select
                  placeholder="Relevent"
                  className="border text-gray p-1 w-[201px] h-[34px]"
                >
                  <option>Relvent</option>
                </select>
              </div>
            </div>
          </div>
          {data?.reviews?.map((rev) => {
            const { id, name, review } = rev;
            return (
              <div
                key={id}
                className="flex flex-col gap-y-8 md:flex-row pb-[50px] border-b border-gray"
              >
                <div className="flex-1 flex sm:flex-row flex-col gap-y-4 gap-x-4">
                  <div className=" flex flex-col gap-y-5">
                    <h3 className="text-3xl font-bold text-primary capitalize">
                      {name}
                    </h3>
                    <h4 className="font-light text-gray text-2xl">
                      {moment(rev.createdAt).format("DD-MM-YYYY")}
                    </h4>
                    <Rating
                      initialRating={review}
                      readonly
                      emptySymbol={
                        <div className="text-2xl 2xl:text-4xl">
                          <AiOutlineStar />
                        </div>
                      }
                      fullSymbol={
                        <div className="text-2xl 2xl:text-4xl text-[#FFB801]">
                          <AiTwotoneStar />
                        </div>
                      }
                    />
                  </div>
                </div>
                <div className=" flex-[3] flex ">
                  <h3 className=" text-[24px] text-primary">{rev?.comment}</h3>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Reviews;

const reviews = [
  {
    id: 1,
    name: "terry jones",
    rating: 5,
    review: "Smells nice, not sticky at all. Wil buy again",
  },
  {
    id: 2,
    name: "terry jones",
    rating: 5,
    review: "Smells nice, not sticky at all. Wil buy again",
  },
  {
    id: 3,
    name: "terry jones",
    rating: 5,
    review: "Smells nice, not sticky at all. Wil buy again",
  },
];
