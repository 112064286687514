import instance from "./axiosConfig";

function token() {
  if (localStorage.getItem("token")) {
    let data = localStorage.getItem("token");
    let parseData = JSON.parse(data);

    return parseData.token;
  }
  return null;
}

function headers() {
  return {
    "Content-Type": "application/json",
    "x-access-token": token(),
  };
}
export function register(data) {
  return instance.post("/admin/register", data, { headers: headers() });
}
export function adminLogin(data) {
  return instance.post("/admin/login", data, { headers: headers() });
}
export function userlogin(data) {
  return instance.post("/admin/userlogin", data, { headers: headers() });
}
export function saveBrand(data) {
  return instance.post("/brand/saveBrand", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": token(),
    },
  });
}
export function getallBrand() {
  return instance.get("/brand/getallBrand", { headers: headers() });
}
export function updateBrand(data) {
  return instance.post("/brand/updateBrand", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": token(),
    },
  });
}
export function deleteBrand(data) {
  return instance.post("/brand/deleteBrand", data, { headers: headers() });
}
export function saveCategories(data) {
  return instance.post("/category/saveCategories", data, {
    headers: headers(),
  });
}
export function getallCategories() {
  return instance.get("/category/getallCategories", { headers: headers() });
}
export function getCategoriesbyid(data) {
  return instance.post("/category/getCategoriesbyid", data, {
    headers: headers(),
  });
}
export function allcustomer() {
  return instance.get("/admin/allcustomer", { headers: headers() });
}
export function updatprofile(data) {
  return instance.post("/admin/updatprofile", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": token(),
    },
  });
}
export function saveFAQ(data) {
  return instance.post("/faq/saveFAQ", data, {
    headers: headers(),
  });
}
export function saveProduct(data) {
  return instance.post("/product/saveProduct", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": token(),
    },
  });
}
export function getallProduct() {
  return instance.get("/product/getallProduct", { headers: headers() });
}
export function addcart(data) {
  return instance.post("/product/addcart", data, { headers: headers() });
}
export function getAllCart(data) {
  return instance.post("/product/getAllCart", data, { headers: headers() });
}
export function updateCart(data) {
  return instance.post("/product/updateCart", data, { headers: headers() });
}
export function deleteCart(data) {
  return instance.post("/product/deleteCart", data, { headers: headers() });
}
export function addWishList(data) {
  return instance.post("/product/addWishList", data, { headers: headers() });
}
export function getWishList(data) {
  return instance.post("/product/getWishList", data, { headers: headers() });
}
export function saveAddress(data) {
  return instance.post("/order/saveAddress", data, { headers: headers() });
}
export function getAlladdress(data) {
  return instance.post("/order/getAlladdress", data, { headers: headers() });
}
export function saveOrder(data) {
  return instance.post("/order/saveOrder", data, { headers: headers() });
}

export function productBybrand(data) {
  return instance.post("/product/productBybrand", data, { headers: headers() });
}
export function paypal_payment(data) {
  return instance.post("/order/payment", data, { headers: headers() });
}
export function stripe_payment(data) {
  return instance.post("/order/stripe/payment", data, { headers: headers() });
}
export function getallOrders(data) {
  return instance.post("/order/getallUserOrders", data, { headers: headers() });
}
export function addReview(data) {
  return instance.post("/order/addReview", data, { headers: headers() });
}
export function verifyCuppon(data) {
  return instance.post("/order/verifyCuppon", data, { headers: headers() });
}
export function updateStatus(data) {
  return instance.post("/order/updateStatus", data, { headers: headers() });
}
export function updatePassword(data) {
  return instance.post("/admin/updatePassword", data, { headers: headers() });
}
export function getallSettings() {
  return instance.get("setting/getAll", {
    headers: headers(),
  });
}
export function getallFAQ() {
  return instance.get("/faq/getallFAQ", {
    headers: headers(),
  });
}
