import React, { useEffect, useState, useContext } from "react";
import Layout from "../../ModuleComponents/Layout/Layout";
import Products from "../Home/Products";
import Vouchers from "./Vouchers";
import gucci from "../../../images/gucci.png";
import vougue from "../../../images/vougue.png";
import brickell from "../../../images/brickell.png";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { getallProduct } from "../../../api/api";

const Index = () => {
  const [Best, setBest] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [Random, setRandom] = useState();
  function getMultipleRandom(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  }

  useEffect(() => {
    brandData();
  }, []);
  const brandData = () => {
    getallProduct()
      .then((res) => {
        setBest(res.data.data);
        setRandom(getMultipleRandom(res.data.data, 4));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout>
      <Vouchers />
      {isLoading ? (
        <Stack
          sx={{ color: "grey.500", marginLeft: "45%" }}
          spacing={2}
          direction="row"
        >
          <CircularProgress color="success" />
        </Stack>
      ) : (
        <Products data={Random} title={`Related Products`} />
      )}
    </Layout>
  );
};

export default Index;
