import React, { useState, useEffect } from "react";
import AutoComplete from "../AutoComplete";
import { BsSearch } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { getallCategories } from "../../../api/api";

const SubMenueComponenet = ({ SubMenue, toggleCategories }) => {
  let navigate = useNavigate();
  const [data, setCategores] = useState();
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    brandData();
  }, []);
  const brandData = () => {
    getallCategories()
      .then((res) => {
        setCategores(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        className={`${
          SubMenue ? "h-[500px] overflow-scroll" : "h-0 overflow-hidden"
        } w-full top-0 lg:top-[98px]   z-[1012] overflow-hidden absolute  transition-all duration-300 shadow-2xl bg-[#FBFBFB]`}
      >
        <div className="py-[18px] relative px-[38px]">
          <button
            onClick={toggleCategories}
            className="text-3xl absolute top-5 right-5 font-bold text-primary"
          >
            x
          </button>
          <div className="w-full pr-10">
            <div className="border-2 mx-auto mb-[30px] flex items-center border-gray h-[47px]  md:max-w-[685px] w-full pl-7">
              <AutoComplete
                placeholder={`Search on ezpurchase...`}
                data={autoCompleteData}
              />
              <Link className="text-[23px] text-gray max-w-max px-7" to="/">
                <BsSearch />
              </Link>
            </div>
          </div>
          <div className="grid grid-cols-[1fr_1fr] sm:grid-cols-[1fr_3fr]  md:grid-cols-[1fr_5fr] lg:grid-cols-[1fr_6fr] 2xl:grid-cols-[1fr_7fr] gap-x-4">
            <div>
              <h2
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/products`)}
                className="pb-2 border-b text-xl font-bold max-w-max pr-4 border-gray mb-5"
              >
                All Categories
              </h2>
            </div>

            <div className=" grid gap-x-4 grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-y-5">
              {data?.map((e) => {
                return (
                  <div key={e?._id}>
                    <h2
                      style={{ cursor: "pointer" }}
                      className="pb-2 border-b text-xl font-bold max-w-max pr-4 border-gray mb-3"
                      onClick={() =>
                        navigate(`/categorybyId/${e._id}`, { state: e._id })
                      }
                    >
                      {e.name}
                    </h2>
                    <ul className="flex flex-col gap-y-3">
                      {e?.subCategory?.map((a) => {
                        return (
                          <li
                            key={a._id}
                            className="text-lg text-primary capitalize"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(`/categorybyId/${e._id}`, {
                                state: e._id,
                              })
                            }
                          >
                            {a.name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubMenueComponenet;

const autoCompleteData = [
  "Asparagus",
  "Aaaaragus",

  "Aasdparagus",

  "Aafggparagus",

  "Aafhwparagus",

  "Aaadtwparagus",

  "Beetroot",
  "Broccoli",
  "Cabbage",
  "Carrot",
  "Cauliflower",
  "Celery",
  "Corn",
  "Eggplant",
  "Lettuce",
  "Mushroom",
  "Onion",
  "Parsnip",
  "Pea",
  "Potato",
  "Pumpkin",
  "Radish",
  "Spinach",
  "Tomato",
  "Turnip",
];
