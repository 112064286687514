import React from "react";
import user1 from "../../../images/avatar1.png";
import lang from "../../../images/language.png";
import gif from "../../../images/gif.png";
import media from "../../../images/media.png";
import mic from "../../../images/mic.png";

const EzPurchaseCenter = () => {
  return (
    <div
      style={{ scrollbarWidth: "scroll" }}
      className="py-16 px-[25px] flex flex-col  relative "
    >
      <div
        style={{ scrollbarWidth: "none" }}
        className="flex flex-col gap-y-4 py-5  overflow-y-scroll h-[450px] md:h-[500px] mt-auto"
      >
        {users.map((msg) => {
          const { id, avatar, user, name, message, date } = msg;
          return (
            <div
              className={`${
                user === "bot" ? "justify-end" : "justify-start"
              } flex items-end w-full gap-x-2`}
              key={id}
            >
              <div
                className={`${
                  user === "bot" ? "order-last" : "order-first"
                } flex gap-y-2 items-center flex-col`}
              >
                <p>{name}</p>
                <img src={avatar} alt="user" />
              </div>
              <div className="flex flex-col pl-[10px] pr-6 py-4 bg-[#C4C4C4] max-w-xs w-full relative">
                <p className={` text-primary text-xl`}>{message}</p>
                <p className="text-gray w-full flex justify-end">{date}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="w-full fixed left-0 bottom-0 sm:bottom-0 z-50 px-7 gap-x-4 py-5 flex bg-white items-center border-t border-gray">
        <div className="flex items-center gap-x-2">
          <button>
            <img src={lang} alt="lang" />
          </button>
          <button>
            <img src={gif} alt="lang" />
          </button>
          <button>
            <img src={media} alt="lang" />
          </button>
          <button>
            <img src={mic} alt="lang" />
          </button>
        </div>
        <div className="h-[50px] max-w-[353px] px-4 flex items-center w-full border-gray border">
          <input
            type={`text`}
            placeholder="Start a conversation"
            className="focus:outline-none text-lg text-gray w-full"
          />
        </div>
        <button className="text-primary text-lg font-bold capitalize">
          send
        </button>
      </div>
    </div>
  );
};

export default EzPurchaseCenter;

const users = [
  {
    id: 1,
    avatar: user1,
    user: "customer",
    name: "hamza",
    message: "Hi there!",
    date: "2 May",
  },
  {
    id: 2,
    avatar: user1,
    user: "bot",
    name: "ahmad",
    message: "Hi there!",
    date: "2 May",
  },
  {
    id: 3,
    avatar: user1,
    user: "customer",
    name: "hamza",
    message: "Hi there!",
    date: "2 May",
  },
  {
    id: 4,
    avatar: user1,
    user: "bot",
    name: "ahmad",
    message: "Hi there!",
    date: "2 May",
  },
  {
    id: 5,
    avatar: user1,
    user: "bot",
    name: "ahmad",
    message: "Hi there!",
    date: "2 May",
  },
  {
    id: 6,
    avatar: user1,
    user: "bot",
    name: "ahmad",
    message: "Hi there!",
    date: "2 May",
  },
  {
    id: 7,
    avatar: user1,
    user: "bot",
    name: "ahmad",
    message: "Hi there!",
    date: "2 May",
  },
];
