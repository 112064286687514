import React from "react";
import Button from "../../ModuleComponents/Button";

const Address = ({ setComponent }) => {
  return (
    <div className="flex flex-col">
      <h4 className="text-[30px] font-bold mb-6 text-primary capitalize">
        address
      </h4>
      <h4 className="text-xl font-bold text-primary mb-[10px] capitalize">
        Current Address
      </h4>
      <p className="text-primary capitalize text-xl mb-6">
        12 Street, Calli Tower, 2nd Floor, California
      </p>

      <input
        type={`email`}
        placeholder="Enter New Address"
        required
        className="px-[13px] max-w-[440px] mb-12 text-primary h-[50px] w-full border-gray border focus:outline-none  py-[11px]"
      ></input>

      <div className="flex flex-col gap-y-[50px]">
        <Button
          onClick={() => setComponent("password")}
          width={308}
          height={70}
          secondary
        >
          Change Password
        </Button>
        <Button width={308} height={70}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default Address;
