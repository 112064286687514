import React, { useContext, useEffect, useState } from "react";
import Layout from "../../ModuleComponents/Layout/Layout";
import Header from "./Header";
import ShopByBrands from "./ShopByBrands";
import SpecialOffers from "./SpecialOffers";
import TopCard from "./TopCard";

import Products from "./Products";
import { getallProduct, getallSettings } from "../../../api/api";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
const Home = () => {
  const [Men, setMen] = useState();
  const [Women, setWomen] = useState();
  const [Best, setBest] = useState();
  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    brandData();
  }, []);
  const brandData = () => {
    getallProduct()
      .then((res) => {
        setMen(res.data.men?.filter((e) => e.product_status === "Active"));
        setWomen(res.data.women?.filter((e) => e.product_status === "Active"));
        setBest(res.data.best?.filter((e) => e.product_status === "Active"));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    getallSettings().then((res) => {
      setData(res.data.data);
    });
  };

  return (
    <Layout>
      <Header data={data} />
      <TopCard data={data} />
      <ShopByBrands />
      {isLoading ? (
        <Stack
          sx={{ color: "grey.500", marginLeft: "45%" }}
          spacing={2}
          direction="row"
        >
          <CircularProgress color="success" />
        </Stack>
      ) : (
        <Products
          data={Women ? Women.slice(0, 4) : []}
          title={`Women’s Favourite`}
        />
      )}
      {isLoading ? (
        <Stack
          sx={{ color: "grey.500", marginLeft: "45%" }}
          spacing={2}
          direction="row"
        >
          <CircularProgress color="success" />
        </Stack>
      ) : (
        <Products data={Best ? Best.slice(0, 4) : []} title={`Best Sellers`} />
      )}
      {isLoading ? (
        <Stack
          sx={{ color: "grey.500", marginLeft: "45%" }}
          spacing={2}
          direction="row"
        >
          <CircularProgress color="success" />
        </Stack>
      ) : (
        <Products
          data={Men ? Men.slice(0, 4) : []}
          title={`Men’s Collection`}
        />
      )}
      <SpecialOffers
        Idata={data}
        data={Best ? Best.slice(0, 2) : []}
        title={`special offers`}
      />
    </Layout>
  );
};

export default Home;
