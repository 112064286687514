import React from "react";
import { useState } from "react";
import NewPassword from "./NewPassword";
import Layout from "../../ModuleComponents/Layout/Layout";
import Address from "./Address";

const Index = () => {
  const [component, setComponent] = useState("address");
  return (
    <Layout>
      <div className="main-container py-28">
        <h3 className="section-title mb-[30px]">Settings</h3>
        {component === "address" && <Address setComponent={setComponent} />}
        {component === "password" && (
          <NewPassword setComponent={setComponent} />
        )}
      </div>
    </Layout>
  );
};

export default Index;
