import moment from "moment";
import React, { useState, useEffect } from "react";
import img3 from "../../../images/product/img1.png";
import { getAlladdress } from "../../../api/api";
import Chip from "@mui/material/Chip";
import { MyImage } from "./MyImage";
let initialValue = 0;
const OrderPlaced = ({ data }) => {
  const [address, setAddress] = useState([]);
  let Wg = data?.orderItem?.reduce(
    (a, c) => a + c.weight * c.Qty,
    initialValue
  );
  let currentuser = JSON.parse(localStorage.getItem("auth_user"));

  useEffect(() => {
    brandData();
  }, []);
  const brandData = () => {
    getAlladdress({ id: currentuser.user._id })
      .then((res) => {
        setAddress(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let s_address = address?.filter((e) => e._id === data?.shipping_address)[0];
  console.log(data);
  return (
    <div className="pt-20 pb-12">
      <div className="flex lg:flex-row flex-col">
        <div className="flex-[2] lg:pr-7">
          <div className="lg:max-w-[806px]  w-full">
            <h3 className="text-primary pb-4 border-b border-gray font-bold text-2xl capitalize mb-[30px]">
              <span className="text-gray">Chargeable Weigth: {Wg} kg</span>
            </h3>
            <div className="flex flex-col gap-y-[30px] mb-[15px]">
              <div className="flex gap-x-5">
                <div className="flex-1">
                  <p className="text-xl text-primary font-bold">Order Date:</p>
                </div>
                <div className="flex-[3]">
                  <p className="text-xl text-primary">
                    {moment(data?.createdAt).format("DD-MM-YYYY")}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-[30px] mb-[15px]">
              <div className="flex gap-x-5">
                <div className="flex-1">
                  <p className="text-xl text-primary font-bold">
                    Delivery Address:
                  </p>
                </div>
                <div className="flex-[3]">
                  <p className="text-xl text-primary">
                    {s_address?.address}, {s_address?.city}, {s_address?.state}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-[30px] mb-[15px]">
              <div className="flex gap-x-5">
                <div className="flex-1">
                  <p className="text-xl text-primary font-bold">
                    Delivery Method:
                  </p>
                </div>
                <div className="flex-[3]">
                  <p className="text-xl text-primary">Sea</p>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-y-[30px] mb-[15px]">
              <div className="flex gap-x-5">
                <div className="flex-1">
                  <p className="text-xl text-primary font-bold">
                    Payment Satatus:
                  </p>
                </div>
                <div className="flex-[3]">
                  <p className="text-xl text-primary">{data?.paymentInfo}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-[30px] mb-[15px]">
              <div className="flex gap-x-5">
                <div className="flex-1">
                  <p className="text-xl text-primary font-bold">Payment No:</p>
                </div>
                <div className="flex-[3]">
                  <p className="text-xl text-primary">
                    {" "}
                    {data?.paymentInfo === "Pending"
                      ? data?.paymentInfo
                      : data?.paymentId}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-[30px] mb-[15px]">
              <div className="flex gap-x-5">
                <div className="flex-1">
                  <p className="text-xl text-primary font-bold">
                    User Information:
                  </p>
                </div>
                <div className="flex-[3]">
                  <p className="text-xl text-primary">
                    {currentuser?.user?.email}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-[30px] mb-[15px]">
              <div className="flex gap-x-5">
                <div className="flex-1">
                  <p className="text-xl text-primary font-bold">
                    Estimate Arrival Date:
                  </p>
                </div>
                <div className="flex-[3]">
                  <p className="text-xl text-primary">
                    {moment(data?.createdAt).add(5, "d").format("DD-MM-YYYY")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 flex flex-col gap-y-[30px]">
          <div className="py-[20px] px-[25px] border border-primary">
            <h3 className="text-primary mb-5 font-bold text-3xl capitalize ">
              Order Summary
            </h3>

            <div className="flex  flex-col  pb-5 border-b border-gray">
              <div className="flex justify-between mb-5">
                <p className="text-2xl text-primary capitalize">Order ID</p>
                <p className="text-2xl text-primary capitalize">
                  #{data?._id.slice(0, 12)}
                </p>
              </div>

              <div className="flex justify-between">
                <p className="text-2xl text-primary capitalize">
                  Payment Method
                </p>
                <p className="text-2xl text-primary capitalize">
                  {data?.paymentMethod}
                </p>
              </div>
            </div>

            <div className="py-5 border-b border-gray">
              <div className="flex justify-between">
                <p className="text-2xl text-primary capitalize">Shipping Fee</p>
                <p className="text-2xl text-gray capitalize">
                  ${data?.shippingPrice}
                </p>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-between">
                <p className="text-2xl text-primary capitalize font-bold">
                  Total Amount
                </p>
                <p className="text-2xl text-primary font-bold capitalize">
                  ${data?.totalPrice}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {data?.orderItem?.map((e) => (
        <div className="flex md:flex-row flex-col gap-y-8  border-t border-gray pt-[30px] lg:mr-8  pb-[30px]">
          <div className="flex-[1] flex gap-x-[50px] ">
            <div className="flex">
              <MyImage
                dimension={105}
                borderRadius="0%"
                src={e?.product_image}
              />
            </div>
            <div className="flex-[4] flex-col gap-y-4">
              <h3 className="text-primary capitalize font-bold text-xl">
                {e.name}
              </h3>
              <p className="text-xl text-primary">
                {e?.description?.substring(0, 25)}....
              </p>
            </div>{" "}
          </div>

          <div className="flex-[2] flex">
            <div className="flex-1 justify-center">
              <div className="flex flex-col gap-y-4 items-center">
                <p className="text-xl text-gray font-bold capitalize">
                  {e.V1.split(":")[0]}
                </p>
                <p className="text-xl text-primary capitalize">
                  {" "}
                  {e.V1.split(":")[1]}
                </p>
              </div>
            </div>

            <div className="flex-[1] justify-center">
              <div className="flex flex-col gap-y-4 items-center">
                <p className="text-xl text-gray font-bold capitalize">
                  {e.V2.split(":")[0]}
                </p>
                <p className="text-xl text-primary capitalize">
                  {e.V2.split(":")[1]}
                </p>
              </div>
            </div>
            <div className="flex-[1]  justify-center">
              <div className="flex flex-col gap-y-4 items-center">
                <p className="text-xl text-gray font-bold capitalize">
                  Order No
                </p>
                <p className="text-xl text-primary capitalize">
                  {" "}
                  #{data?._id.slice(0, 4)}
                </p>
              </div>
            </div>
            <div className="flex-[1]  justify-center">
              <div className="flex flex-col gap-y-4 items-center">
                <p className="text-xl text-gray font-bold capitalize">
                  Quantity
                </p>
                <p className="text-xl text-primary capitalize">{e?.Qty}</p>
              </div>
            </div>
            <div className="flex-[1.1]  justify-center">
              <div className="flex flex-col gap-y-4 items-center">
                <p className="text-xl text-gray font-bold capitalize">
                  Shipping Fee
                </p>
                <p className="text-xl text-primary capitalize">
                  ${data?.shippingPrice}
                </p>
              </div>
            </div>
            <div className="flex-[1]  justify-center">
              <div className="flex flex-col gap-y-4 items-center">
                <p className="text-xl text-gray font-bold capitalize">Price</p>
                <p className="text-xl text-primary capitalize">$ {e?.price}</p>
              </div>
            </div>
            <div className="flex-[1.1]  justify-center">
              <div className="flex flex-col gap-y-4 items-center">
                <p className="text-xl text-gray font-bold capitalize">
                  Order Status
                </p>
                <p className="text-xl text-primary capitalize">
                  <Chip
                    sx={{
                      backgroundColor:
                        data?.orderStatus === "Pending"
                          ? "yellow"
                          : data?.orderStatus ===
                            "Order Have Been Send to Courier"
                          ? "blue"
                          : data?.orderStatus === "Received"
                          ? "green"
                          : "",

                      color:
                        data?.orderStatus ===
                          "Order Have Been Process By Admin" ||
                        data?.orderStatus === "Delivered" ||
                        data?.orderStatus === "Pending"
                          ? "black"
                          : "white",
                      fontSize: 15,
                    }}
                    label={
                      data?.orderStatus
                        ? data?.orderStatus ===
                          "Order Have Been Send to Courier"
                          ? "Shipped"
                          : data?.orderStatus
                        : ""
                    }
                  />
                  {/* {data?.orderStatus === "Order Have Been Send to Courier"
                    ? "Shipped"
                    : data?.orderStatus} */}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrderPlaced;

const orderDtail = [
  { id: 1, title: "Order Date:", desc: "16/11/2021 13 : 20" },
  { id: 2, title: "Payment No:", desc: "45154523456236256" },
  { id: 3, title: "Delivery Method:", desc: "Home" },

  {
    id: 4,
    title: "Delivery Address:",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
  },
  {
    id: 5,
    title: "User Information:",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
  },
  {
    id: 6,
    title: "Estimated Arrival Date:",
    desc: "Lorem ipsum dolor sit amet.",
  },
];
