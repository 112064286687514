import React from "react";
import vector1 from "../../../images/black-vector.png";
import vector2 from "../../../images/pink-vector.png";
import user from "../../../images/user.png";

const Profile = () => {
  let currentuser = JSON.parse(localStorage.getItem("auth_user"));
  return (
    <div>
      <div className="relative">
        <img className="top-0 relative" src={vector1} alt="vector" />
        <img className="-top-3 lg:-top-5 relative" src={vector2} alt="vector" />
      </div>

      <div className="py-[50px] px-4">
        <div className="flex flex-col justify-center">
          <div className="rounded-full mb-5 mx-auto overflow-hidden w-[157px] h-[157px] flex justify-center items-center relative">
            <img src={currentuser?.user?.pImage} alt="check" />
          </div>

          <p className="text-primary text-center font-bold text-xl lg:text-2xl capitalize">
            {currentuser?.user?.name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
