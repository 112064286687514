import React from "react";
import FilterProducts from "../../AbstractComponents/FilterProducts";

const Wishlist = ({ data }) => {
  return (
    <div>
      <FilterProducts data={data} />
    </div>
  );
};

export default Wishlist;
