import React from "react";
import parse from "html-react-parser";
const SpecsFeatures = ({ data }) => {
  console.log(data);
  return (
    <div
      className="bg-cover bg-center py-[35px] w-full"
      style={{
        backgroundImage: `linear-gradient( 270deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.75) 47.81%, #FFFFFF 100%), url(${
          data ? data?.product_image[0]?.img : "https://picsum.photos/1001/1001"
        })`,
      }}
    >
      <div className="main-container">{parse(data?.product_detail)}</div>
    </div>
  );
};

export default SpecsFeatures;
