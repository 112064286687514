import React from "react";
import vector1 from "../../../images/black-vector.png";
import vector2 from "../../../images/pink-vector.png";
import Button from "../../ModuleComponents/Button";
import check from "../../../images/check.svg";

const Confirmation = ({ setIndex }) => {
  return (
    <div>
      <div className="relative">
        <img className="top-0 relative" src={vector1} alt="vector" />
        <img className="-top-3 lg:-top-5 relative" src={vector2} alt="vector" />
      </div>

      <div className="py-[50px] px-4">
        <div className="rounded-full mx-auto mb-10 border-[6px] w-[157px] h-[157px] flex justify-center items-center border-[#FF6969] relative">
          <img src={check} alt="check" />
        </div>

        <div className="mb-[126px] flex flex-col max-w-[440px] mx-auto w-full">
          <p className="text-center text-primary text-lg lg:text-xl">
            Congratulations your password has been successfully reset.
          </p>
        </div>

        <div className="flex flex-col">
          <Button
            type={`submit`}
            width={308}
            onClick={() => setIndex(7)}
            className={`mb-[30px] lg:h-[70px] h-[60px] mx-auto`}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
