import React, { useState, useEffect, useContext } from "react";
import { AiOutlineStar, AiTwotoneStar } from "react-icons/ai";
import Typography from "@mui/material/Typography";
import img3 from "../../../images/product/img1.png";
import Button from "../../ModuleComponents/Button";
import { toast } from "react-toastify";
import ToastContext from "../../../context/ToastContext";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import { MyImage } from "./MyImage";
import { addReview } from "../../../api/api";
import { useNavigate } from "react-router-dom";
const OrderComplete = ({ data }) => {
  const toastOptions = useContext(ToastContext);
  const [value, setValue] = useState(0);
  const [Review, setReview] = useState("");
  let navigate = useNavigate();

  return (
    <div className="pt-[50px] pb-24">
      <div className="py-[50px] mb-[50px] text-center">
        <h2 className="capitalize text-4xl text-primary font-bold mb-[25px]">
          Congratulations Your Order Has Been Complete!
        </h2>
        <p className="text-3xl capitalize">
          Give Us Review For Our Improvement.
        </p>
      </div>

      {data?.orderItem?.map((e) => (
        <div className="flex md:flex-row flex-col gap-y-8  border-b border-t border-gray pt-[30px] lg:mr-8  pb-[30px]">
          <div className="flex-1 flex gap-x-[40px] ">
            <div className="flex-1">
              <MyImage
                dimension={105}
                borderRadius="0%"
                src={e?.product_image}
              />
            </div>
            <div className="flex-[2] flex flex-col gap-y-4">
              <h3 className="text-primary capitalize font-bold text-2xl">
                {e.name}
              </h3>
              <p className="text-2xl text-primary">
                {e?.description?.substring(0, 25)}....
              </p>
            </div>{" "}
          </div>

          <div className="flex-1 flex">
            <div className="flex-1 flex justify-center">
              <div className="flex flex-col gap-y-4 items-center">
                <p className="text-2xl text-gray font-bold capitalize">
                  {e.V1.split(":")[0]}
                </p>
                <p className="text-2xl text-primary capitalize">
                  {" "}
                  {e.V1.split(":")[1]}
                </p>
              </div>
            </div>

            <div className="flex-1 flex justify-center">
              <div className="flex flex-col gap-y-4 items-center">
                <p className="text-2xl text-gray font-bold capitalize">
                  {e.V2.split(":")[0]}
                </p>
                <p className="text-2xl text-primary capitalize">
                  {e.V2.split(":")[1]}
                </p>
              </div>
            </div>
            <div className="flex-1 flex justify-center">
              <div className="flex flex-col gap-y-4 items-center">
                <p className="text-2xl text-gray font-bold capitalize">
                  Quantity
                </p>
                <p className="text-2xl text-primary capitalize">{e?.Qty}</p>
              </div>
            </div>
            <div className="flex-1 flex justify-center">
              <div className="flex flex-col gap-y-4 items-center">
                <p className="text-2xl text-gray font-bold capitalize">Price</p>
                <p className="text-2xl text-primary capitalize">$ {e?.price}</p>
              </div>
            </div>
            <div className="flex-2 flex justify-center">
              <div className="flex flex-col gap-y-4 items-center">
                <p className="text-2xl text-gray font-bold capitalize">
                  Order Status:
                </p>
                <p className="text-2xl text-primary capitalize">
                  {data?.orderStatus}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="mt-[56px]">
        <div className="flex gap-x-7">
          <Box
            sx={{
              "& > legend": { mt: 2 },
            }}
          >
            <Rating
              name="simple-controlled"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              sx={{ marginBottom: 2 }}
              size="large"
            />
          </Box>
        </div>
        <div className="flex flex-col">
          <h3 className="text-3xl font-bold text-primary mb-[25px]">
            Add a Review
          </h3>
          <textarea
            onChange={(e) => setReview(e.target.value)}
            placeholder="Write your review..."
            className="min-h-[130px] w-full focus:outline-none border-2 border-gray p-4"
          ></textarea>
          {value === 0 ? (
            ""
          ) : (
            <Button
              className={`min-h-[66px] mt-[30px] w-full`}
              width={309}
              onClick={() =>
                addReview({
                  id: data?._id,
                  review: value,
                  comment: Review,
                }).then((res) => {
                  toast.success(res.data.message, toastOptions);
                  navigate("/");
                })
              }
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderComplete;
