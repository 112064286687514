import React, { useState, useContext } from "react";
import vector1 from "../../../images/black-vector.png";
import vector2 from "../../../images/pink-vector.png";
import Button from "../../ModuleComponents/Button";
import { toast } from "react-toastify";
import ToastContext from "../../../context/ToastContext";
import { updatePassword } from "../../../api/api";
let currentuser = JSON.parse(localStorage.getItem("auth_user"));
const NewPassword = ({ setIndex }) => {
  const toastOptions = useContext(ToastContext);
  const [newPassword, setNewPassword] = useState({
    id: currentuser.user._id,
    current: "",
    pass: "",
    confirm: "",
  });
  return (
    <div>
      <div className="relative">
        <img className="top-0 relative" src={vector1} alt="vector" />
        <img className="-top-3 lg:-top-5 relative" src={vector2} alt="vector" />
      </div>

      <div className="py-[50px] px-4">
        <h3 className="text-center mx-auto max-w-[308px] capitalize font-bold text-xl lg:text-2xl text-primary pb-[25px] border-b border-[#999999]">
          New Password
        </h3>

        <div className="mt-[93px] mb-[94px] flex flex-col max-w-[440px] mx-auto w-full">
          <form className="flex flex-col gap-y-[25px]">
            <input
              type={`password`}
              onChange={(e) =>
                setNewPassword({ ...newPassword, current: e.target.value })
              }
              required
              placeholder="Current Password"
              className="px-[13px] text-primary h-[50px] w-full border-gray border focus:outline-none  py-[11px]"
            ></input>
            <input
              type={`password`}
              onChange={(e) =>
                setNewPassword({ ...newPassword, pass: e.target.value })
              }
              required
              placeholder="New Password"
              className="px-[13px] text-primary h-[50px] w-full border-gray border focus:outline-none  py-[11px]"
            ></input>
            <input
              type={`password`}
              onChange={(e) =>
                setNewPassword({ ...newPassword, confirm: e.target.value })
              }
              required
              placeholder="Confirm Password"
              className="px-[13px] text-primary h-[50px] w-full border-gray border focus:outline-none  py-[11px]"
            ></input>
          </form>
        </div>

        <div className="flex flex-col">
          <Button
            onClick={() =>
              newPassword.pass !== newPassword.confirm
                ? toast.error("Confirm Password Not match", toastOptions)
                : updatePassword(newPassword)
                    .then((res) => {
                      setIndex(2);
                      toast.success(res.data.message, toastOptions);
                    })
                    .catch((err) => {
                      toast.error(err.response.data.error, toastOptions);
                    })
            }
            type={`submit`}
            width={308}
            className={`mx-auto lg:h-[70px] h-[60px]`}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
