import React, { useEffect, useState } from "react";
import { BsEmojiSmile } from "react-icons/bs";
import { Link } from "react-router-dom";
import Products from "../Home/Products";
import Button from "../../ModuleComponents/Button";
import { getallProduct } from "../../../api/api";
const Congratulations = () => {
  const [Best, setBest] = useState([]);
  const [Random, setRandom] = useState();
  function getMultipleRandom(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  }
  useEffect(() => {
    brandData();
  }, []);
  const brandData = () => {
    getallProduct()
      .then((res) => {
        setBest(res.data.data);
        setRandom(getMultipleRandom(res.data.data, 4));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="flex flex-col items-center">
      <div className="text-[224px] py-[80px]">
        <BsEmojiSmile />
      </div>

      <div className="flex flex-col items-center max-w-[825px]">
        <h2 className="text-primary font-bold text-center text-5xl mb-[60px]">
          Congratulations Your Order Has Been Successfully Placed!
        </h2>
        <Button width={309} height={70}>
          <Link
            className="w-full h-full flex items-center justify-center"
            to="/"
          >
            Sure
          </Link>
        </Button>
      </div>
      <Products data={Random} title={`Related Products`} />
    </div>
  );
};

export default Congratulations;
