import React, { useState, useEffect } from "react";
import vector1 from "../../../images/black-vector.png";
import vector2 from "../../../images/pink-vector.png";
import Button from "../../ModuleComponents/Button";
import { getAlladdress } from "../../../api/api";
let currentuser = JSON.parse(localStorage.getItem("auth_user"));
const Setting = ({ setIndex }) => {
  const [Address, setAddress] = useState([]);
  useEffect(() => {
    getAddress();
  }, []);
  const getAddress = () => {
    getAlladdress({ id: currentuser.user._id })
      .then((res) => {
        setAddress(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <div className="relative">
        <img className="top-0 relative" src={vector1} alt="vector" />
        <img className="-top-3 lg:-top-5 relative" src={vector2} alt="vector" />
      </div>

      <div className="py-[50px] px-4">
        <h3 className="text-center mx-auto max-w-[308px] capitalize font-bold text-xl lg:text-2xl text-primary pb-[25px] border-b border-[#999999]">
          Setting
        </h3>

        <div className="mt-[50px] mb-[20px] flex flex-col max-w-[440px] mx-auto w-full">
          <form className="flex flex-col gap-y-[20px]">
            <h2 className="lg:text-xl font-bold">Current Address:</h2>
            <p>
              {`${Address[Address.length - 1]?.address}, ${
                Address[Address.length - 1]?.city
              }, ${Address[Address.length - 1]?.state}`}
            </p>
            <input
              type={`text`}
              required
              placeholder="Add new Adress"
              className="px-[13px] text-primary h-[50px] w-full border-gray border focus:outline-none  py-[11px]"
            ></input>
          </form>
        </div>
        <div className="flex flex-col mb-[10px]">
          <Button
            onClick={() => setIndex(1)}
            type={`submit`}
            width={308}
            className={`mx-auto lg:h-[70px] h-[60px] bg-change tx-change `}
          >
            Change Password
          </Button>
        </div>
        <div className="flex flex-col">
          <Button
            type={`submit`}
            width={308}
            className={`mx-auto lg:h-[70px] h-[60px]`}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Setting;
