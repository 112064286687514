import React, { useState, useEffect } from "react";
import Faq from "./Faq";
import { AiOutlineFlag } from "react-icons/ai";
import { MdPayment } from "react-icons/md";
import { BsQuestionCircle, BsBook } from "react-icons/bs";
import { FaqCategory } from "./faqCategory";
import { AiOutlineSearch } from "react-icons/ai";
import Icon1 from "./../../../images/Vector (flag).png";
import Icon2 from "./../../../images/Vector (2).png";
import Icon3 from "./../../../images/Vector (Stroke).png";
import Icon4 from "./../../../images/Vector (Stroke) (1).png";
import { getallFAQ } from "../../../api/api";
import Divider from "@mui/material/Divider";

function HelpCenterheader() {
  const [categoryList] = React.useState([
    {
      _id: Math.random() * 1000 * Math.random(),
      icon: Icon1,
      category: "Getting Started",
    },
    {
      _id: Math.random() * 1000 * Math.random(),
      icon: Icon2,
      category: "Payment Methods",
    },
    {
      _id: Math.random() * 1000 * Math.random(),
      icon: Icon3,
      category: "Product Questions",
    },
    {
      _id: Math.random() * 1000 * Math.random(),
      icon: Icon4,
      category: "Usage Guides",
    },
  ]);
  const [faqs, setFaQ] = useState([]);
  const [filter, setfilter] = useState([]);
  const [myCategory, setCategory] = React.useState(categoryList[0]);
  useEffect(() => {
    brandData();
  }, []);
  const brandData = () => {
    getallFAQ()
      .then((res) => {
        setFaQ(res.data.data);
        setfilter(
          res.data.data.filter((e) => e.category === myCategory?.category)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCategory = (data) => {
    setCategory(data);
    setfilter(faqs.filter((e) => e.category === data.category));
  };
  console.log(filter);
  return (
    <div>
      <div className="bg-offwhite px-[50px] mb-[50px] mt-[15px]">
        <p className="text-gray text-2xl">Help Center</p>
      </div>
      <div className="px-[30px]">
        <div className="main-container ">
          <div>
            <h2 className="text-4xl max-w-max pb-3 mb-[30px] font-bold text-primary border-b border-primary">
              Help Center
            </h2>
            {/* <div className="border border-gray px-3 py-[6px] rounded-[3px] max-w-[325px] flex justify-between items-center ">
              <input
                placeholder="Search for an answer......"
                className="placeholder:text-gray focus:outline-none w-[210px] placeholder:text-xl"
              />
              <AiOutlineSearch className="text-gray text-xl" />
            </div> */}
            <p className="pt-[15px] text-secondry text-2xl mb-[30px] ">
              Just choose a category to quickly find the help you need.
            </p>
            <div className="max-w-[1150px] mb-[30px]">
              <div className="grid lg:grid-cols-4 gap-[50px] md:grid-cols-2 grid-cols-1">
                {categoryList.length > 0
                  ? categoryList.map((item, index) => (
                      <FaqCategory
                        marginRight={categoryList.length === index + 1 ? 0 : 50}
                        Icon={item.icon}
                        isSelected={item._id === myCategory._id ? true : false}
                        onClick={() => {
                          handleCategory({ ...item });
                        }}
                      >
                        {item.category}
                      </FaqCategory>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {filter.length > 0
          ? filter?.map((item, index) => (
              <>
                <Faq
                  questionNumber={index + 1}
                  question={item.question}
                  answer={item.answer}
                />
              </>
            ))
          : ""}
      </div>
    </div>
  );
}

export default HelpCenterheader;
