import React from "react";
import vector1 from "../../../images/black-vector.png";
import vector2 from "../../../images/pink-vector.png";
import Button from "../../ModuleComponents/Button";

const ForgottenPassword = ({ setIndex }) => {
  return (
    <div>
      <div className="relative">
        <img className="top-0 relative" src={vector1} alt="vector" />
        <img className="-top-3 lg:-top-5 relative" src={vector2} alt="vector" />
      </div>

      <div className="py-[50px] px-4">
        <h3 className="text-center mx-auto max-w-[308px] capitalize font-bold text-xl lg:text-2xl text-primary pb-[25px] border-b border-[#999999]">
          Forgotten Password
        </h3>

        <div className="mt-[114px] mb-[126px] flex flex-col max-w-[440px] mx-auto w-full">
          <p className="text-lg lg:text-xl mb-[30px]">
            We will send a reset code to your email,
            <br /> input that code to renew your password.
          </p>
          <form>
            <input
              type={`email`}
              placeholder="Email"
              required
              className="px-[13px] text-primary h-[50px] w-full mb-[25px] border-gray border focus:outline-none  py-[11px]"
            ></input>
          </form>
        </div>

        <div className="flex flex-col">
          <Button
            onClick={() => setIndex(4)}
            type={`submit`}
            width={308}
            className={`mb-[30px] h-[60px] lg:h-[70px] mx-auto`}
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ForgottenPassword;
