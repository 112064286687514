import React from "react";
//          Importing Colors

export const MyImage = ({
  src,
  alt,
  dimension,
  placeholderDimension,
  borderRadius,
  cursor,
  allowImageSelection,
  id,
  onChange,
  setimageURL,
  multi,
}) => {
  // state management
  const [imageURL, setImageURL] = React.useState(src);

  React.useEffect(() => {
    if (src) {
      setImageURL(src);
    } else {
      setImageURL("");
    }
  }, [src]);
  const selectMultipleFile = (e) => {
    const myFile = e.target.files;

    if (myFile) {
      if (onChange) {
        onChange(myFile);
      }
    } else {
      console.log("File is not selected");
    }
  };
  const selectFile = (e) => {
    const myFile = e.target.files;
    if (myFile) {
      const blob = URL.createObjectURL(myFile[0]);

      setImageURL(blob);
      if (onChange) {
        onChange(myFile);
      }
    } else {
      console.log("File is not selected");
    }
  };
  //    Styling Components
  const Style = {
    imageLabel: {
      width: `${dimension ? dimension : 43}px`,
      height: `${dimension ? dimension : 43}px`,

      borderRadius: borderRadius ? borderRadius : "50%",
      cursor: cursor ? cursor : "pointer",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    imageContainer: {
      width: `${dimension ? dimension : 43}px`,
      height: `${dimension ? dimension : 43}px`,
      borderRadius: borderRadius ? borderRadius : "50%",
      cursor: cursor ? cursor : "pointer",
    },
    image: {
      width: `${dimension ? dimension : 43}px`,
      height: `${dimension ? dimension : 43}px`,
      objectFit: "cover",
      borderRadius: borderRadius ? borderRadius : "50%",
    },
  };
  if (allowImageSelection) {
    return (
      <>
        {multi === true ? (
          <>
            <label
              htmlFor={id ? id : "my-image"}
              style={{ ...Style.imageLabel }}
            >
              <div style={{ ...Style.imageContainer }}>
                <img
                  style={{ ...Style.image }}
                  src={src}
                  alt={alt ? alt : "my-images"}
                />
              </div>
            </label>
            {multi === true ? (
              <input
                type="file"
                style={{ display: "none" }}
                id={id ? id : "my-image"}
                accept="image/*"
                //multiple="multiple"
                onChange={(e) => {
                  selectMultipleFile(e);
                }}
              />
            ) : (
              <input
                type="file"
                style={{ display: "none" }}
                id={id ? id : "my-image"}
                accept="image/*"
                onChange={(e) => {
                  selectFile(e);
                }}
              />
            )}{" "}
          </>
        ) : (
          <>
            {imageURL ? (
              <label
                htmlFor={id ? id : "my-image"}
                style={{ ...Style.imageLabel }}
              >
                <div style={{ ...Style.imageContainer }}>
                  <img
                    style={{ ...Style.image }}
                    src={imageURL ? imageURL : ""}
                    alt={alt ? alt : "my-images"}
                  />
                </div>
              </label>
            ) : (
              <label
                htmlFor={id ? id : "my-image"}
                style={{ ...Style.imagePlaceholderLabel }}
              ></label>
            )}

            <input
              type="file"
              style={{ display: "none" }}
              id={id ? id : "my-image"}
              accept="image/*"
              onChange={(e) => {
                selectFile(e);
              }}
            />
          </>
        )}
      </>
    );
  } else {
    return (
      <div style={{ ...Style.imageContainer }} id={id ? id : "id"}>
        <img
          style={{ ...Style.image }}
          src={imageURL ? imageURL : ""}
          alt={alt ? alt : "my-images"}
        />
      </div>
    );
  }
};
