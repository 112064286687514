import React from "react";
import { useState } from "react";
import Reviews from "./Reviews";
import SpecsFeatures from "./SpecsFeatures";

const ReviewsSpecs = ({ data }) => {
  console.log(data);
  const [component, setComponent] = useState(true);
  const handleComponent = () => {
    setComponent(!component);
  };
  return (
    <div className="py-10">
      <div className="flex justify-center gap-x-6 mb-[50px]">
        <h3
          onClick={handleComponent}
          className={`${
            component === true ? "text-primary border-b" : "text-gray"
          } font-bold text-[36px] cursor-pointer capitalize max-w-max pb-[12px]  border-primary`}
        >
          product details
        </h3>
        <h3
          onClick={handleComponent}
          className={`${
            component !== true ? "text-primary border-b" : "text-gray"
          } font-bold text-[36px] cursor-pointer capitalize max-w-max pb-[12px]  border-primary`}
        >
          Reviews
        </h3>
      </div>
      {component !== true ? (
        <Reviews data={data} />
      ) : (
        <SpecsFeatures data={data} />
      )}
    </div>
  );
};

export default ReviewsSpecs;
