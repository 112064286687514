import React from "react";
import header from "../../../images/header.png";
import up from "../../../images/up.png";
import down from "../../../images/down.png";
import Button from "../../ModuleComponents/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import "../../../styles/headerSlider.css";

const Header = ({ data }) => {
  SwiperCore.use([Autoplay, Navigation, Pagination]);

  return (
    <div className="relative w-full ">
      <div className="max-w-[1920px] min-h-[730px] h-full mx-auto relative flex justify-start items-center">
        <div className="flex absolute main-container header left-[80px]">
          <img src={down} alt="down" />
          <img src={up} alt="up" />
        </div>

        <div className="  relative h-full w-full">
          <Swiper
            loop="true"
            slidesPerView={1}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            pagination
            navigation
          >
            {data?.slider?.map((e) => (
              <SwiperSlide>
                <div
                  className="flex md:flex-row flex-col h-full min-h-[725px]  "
                  style={{ backgroundImage: `url(${e.img})` }}
                >
                  <div className="flex-1 h-full flex justify-center items-center">
                    <div className="flex flex-col gap-y-[30px]">
                      {/* <h1 className="text-[64px] text-primary">New Arrivals</h1>
                    <Button width={390} height={60}>
                      shop now
                    </Button> */}
                    </div>
                  </div>
                  <div className="flex-1"></div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Header;
