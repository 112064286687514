import React, { useState, useEffect } from "react";
import Layout from "../../ModuleComponents/Layout/Layout";
import { useLocation } from "react-router-dom";
import Wishlist from "../WishlistOrderHistory/Wishlist";
import { getCategoriesbyid, getallProduct } from "../../../api/api";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

const Index = () => {
  const [component, setComponent] = useState(true);
  const [Best, setBest] = useState();

  const [isLoading, setLoading] = useState(true);
  const handleComponent = () => {
    setComponent(!component);
  };
  const id = useLocation();

  useEffect(() => {
    id.state !== null ? brandData() : productData();
  }, []);
  const brandData = () => {
    getCategoriesbyid({ id: id.state })
      .then((res) => {
        setBest(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const productData = () => {
    getallProduct()
      .then((res) => {
        setBest(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout>
      <div className="py-10">
        <div className="main-container">
          {isLoading ? (
            <Stack
              sx={{ color: "grey.500", marginLeft: "45%" }}
              spacing={2}
              direction="row"
            >
              <CircularProgress color="success" />
            </Stack>
          ) : (
            <Wishlist data={Best} />
          )}

          {/* <p className="text-2xl text-gray capitalize">
            {component ? "wishlist" : "order history"}
          </p> */}
          {/* <div className="flex justify-center gap-x-6 mb-[50px]">
            <h3
              onClick={handleComponent}
              className={`${
                component === true ? "text-primary border-b" : "text-gray"
              } font-bold text-[36px] capitalize max-w-max cursor-pointer pb-[12px]  border-primary`}
            >
              
            </h3>
            <h3
              onClick={handleComponent}
              className={`${
                component !== true ? "text-primary border-b" : "text-gray"
              } font-bold text-[36px] capitalize max-w-max cursor-pointer pb-[12px]  border-primary`}
            >
              order history
            </h3>
          </div> */}
          {/* {component === true ? <Wishlist /> : <OrderHistory />} */}
        </div>
      </div>
    </Layout>
  );
};

export default Index;
