import React from "react";
import Layout from "../../ModuleComponents/Layout/Layout";
import HelpCenterheader from "./HelpCenterheader";
import Mail from "./Mail";

const HelpCenter = () => {
  return (
    <Layout>
      <HelpCenterheader />
      <Mail />
    </Layout>
  );
};

export default HelpCenter;
