import React from "react";
import Card from "../../AbstractComponents/Card";
import gucci from "../../../images/gucci.png";
import vougue from "../../../images/vougue.png";
import newSale from "../../../images/NewSale.png";
import { Grid } from "@mui/material";
// SpecialOffers.defaultProps = {
//   // title: "Special Offers",
//   // data: cards,
// };

const SpecialOffers = ({ data, Idata }) => {
  return (
    // <div className="pt-[70px] pb-10">
    //   <div className="main-container">
    //     <h3 className="section-title mb-[50px]">Special Offers</h3>
    //     {/* <div className="flex flex-col gap-y-10 lg:flex-row gap-x-[40px] 2xl:gap-x-[80px]"> */}
    //     {/* <div className="flex-1"> */}
    //     <div className="grid sm:grid-cols-3 gap-y-10 gap-x-[40px] 2xl:gap-x-[80px]">
    //       <div className="lg:max-w-xs 2xl:max-w-[595px] 2xl:w-full">
    //         <img className="w-full" src={newSale} alt="" />
    //       </div>
    //       <Card data={cards} />
    //     </div>
    //     {/* </div> */}
    //   </div>
    //   {/* </div> */}
    // </div>

    <div className="pt-[130px] pb-10">
      <div className="main-container">
        <h3 className="section-title mb-[50px]">{`special offers`}</h3>
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <div style={{ height: "585px" }}>
              <img className="w-full h-full" src={Idata?.special?.img} alt="" />
            </div>
          </Grid>
          <Grid item lg={6}>
            <Grid container>
              <Grid item lg={12}>
                <Card data={data} co={6} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default SpecialOffers;
