import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../images/footer-logo.svg";
import { ImLocation } from "react-icons/im";
import fb from "../../../images/fb.svg";
import twt from "../../../images/twt.svg";
import ins from "../../../images/ins.svg";

const Footer = () => {
  return (
    <div className="bg-secondry py-20 2xl:py-14 min-h-[430px]">
      <div className="main-container">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 2xl:grid-cols-5 gap-y-10 gap-x-5">
          <div>
            <h3 className="footer-widget-title mb-5 2xl:mb-[25px]"></h3>
            <ul className="flex flex-col gap-y-4"></ul>
          </div>

          <div>
            <h3 className="footer-widget-title mb-5 2xl:mb-[25px]"></h3>
            <ul className="flex flex-col gap-y-4">
              <div className="col-start-1 mb-5 col-end-2 sm:col-end-3 md:col-end-5 2xl:col-start-1 2xl:col-end-2">
                <Link to="/">
                  <img
                    src={logo}
                    alt=""
                    style={{
                      position: "relative",
                      top: "100px",
                      right: "160px",
                    }}
                  />
                </Link>
              </div>
            </ul>
          </div>

          <div>
            <h3 className="footer-widget-title mb-5 2xl:mb-[25px]">Navigate</h3>
            <ul className="flex flex-col gap-y-4">
              {links.map((li) => {
                const { id, text, path } = li;
                return (
                  <li key={id}>
                    <Link className="text-2xl text-[#ffffff]" to={path}>
                      {text}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>

          <div>
            <h3 className="footer-widget-title mb-5 2xl:mb-[25px]">
              <Link to={"/HelpCenter"}>Help Center</Link>
            </h3>
            <p className="text-[#ffffff] text-2xl flex gap-x-1">
              <span className="h-max">
                <ImLocation />
              </span>
              51 Tai Seng Avenue,#05-02 <br />
              Pixel Red (Lobby C) <br />
              Singapore 533941
            </p>
            <div className="mt-[50px]">
              <h4 className="text-[#fff] text-2xl font-bold mb-[18px]">
                Follow Us
              </h4>
              <ul className="flex gap-x-[22px]">
                {social.map((ic) => {
                  const { id, icon, url } = ic;
                  return (
                    <li key={id}>
                      <a href={url}>
                        <img src={icon} to={url} alt="icon" />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

const links = [
  { id: 1, text: "Home", path: "/" },
  { id: 2, text: "Categories", path: "/categories" },
  { id: 3, text: "New Arrivals", path: "/new-arrivals" },
  { id: 4, text: "Products", path: "/products" },
  { id: 5, text: "About", path: "/HelpCenter" },
];

const social = [
  { id: 1, icon: fb, url: "facebook" },
  { id: 2, icon: twt, url: "tweeter" },
  { id: 3, icon: ins, url: "instagram" },
];
