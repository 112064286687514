import React from "react";
import { useState, useEffect, useContext } from "react";
import CartItems from "./CartItems";
import Congratulations from "./Congratulations";
import EmptyCart from "./EmptyCart";
import PaymentMethod from "./PaymentMethod";
import PlaceOrder from "./PlaceOrder";
import { useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Layout from "../../ModuleComponents/Layout/Layout";
const PUBLIC_KEY =
  "pk_test_51KhiBwB8UuxyKopHuaiYkJDFwmTqBWhR0HUtBroaxGDQqeVFMzMGIJbdUoLV4Gmfxb1dQu3cAo8CaQVRPQuqlMZM00ur2dTZ9K";
const stripeTestPromise = loadStripe(PUBLIC_KEY);

const Cart = () => {
  let data = useLocation();
  const [step, setStep] = useState(data?.state?.step === 2 ? 2 : 4);
  console.log(data.state);
  const [subtotal, setsubtotal] = useState(data?.state?.subTotal);
  const [methd, setMethod] = useState(false);
  const [Order, setOrder] = useState(false);
  return (
    <Layout>
      <div className="py-10">
        <div className="max-w-[1392px] w-full px-5 mx-auto">
          <ul className="flex mt-[30px] items-center px-28 flex-col md:flex-row flex-wrap gap-y-10 gap-x-2">
            <div className="flex-1 w-full flex items-center gap-x-2">
              <li className="flex flex-col cursor-pointer items-center relative">
                <span
                  className={`text-[20px] font-bold absolute whitespace-nowrap -top-8`}
                >
                  Details
                </span>
                <circle
                  className={`${
                    step <= 0 ? "bg-gray" : "bg-primary"
                  } w-[50px] text-xl rounded-full text-white h-[50px] flex items-center justify-center`}
                >
                  1
                </circle>
              </li>
              <li
                className={`${
                  step < 2 ? "bg-gray" : "bg-primary"
                } flex-1 bg-primary h-[5px]`}
              ></li>

              <li className="flex flex-col cursor-pointer items-center relative">
                <span
                  className={`text-[20px] font-bold absolute whitespace-nowrap -top-8`}
                >
                  Place Order
                </span>
                <circle
                  className={`${
                    step < 2 ? "bg-gray" : "bg-primary"
                  } w-[50px] text-xl rounded-full text-white h-[50px] flex items-center justify-center`}
                >
                  2
                </circle>
              </li>
            </div>
            <li
              className={`${
                step < 3 ? "bg-gray" : "bg-primary"
              } flex-1  md:block hidden  bg-primary h-[5px]`}
            ></li>
            <div className="flex-1 w-full flex gap-x-2 items-center">
              <li className="flex flex-col cursor-pointer items-center relative">
                <span
                  className={`text-[20px] font-bold absolute whitespace-nowrap -top-8`}
                >
                  Payment Method
                </span>
                <circle
                  className={`${
                    step < 3 ? "bg-gray" : "bg-primary"
                  } w-[50px] text-xl rounded-full text-white h-[50px] flex items-center justify-center`}
                >
                  3
                </circle>
              </li>
              <li
                className={`${
                  step < 4 ? "bg-gray" : "bg-primary"
                } flex-1 bg-primary h-[5px]`}
              ></li>

              <li className="flex flex-col cursor-pointer items-center relative">
                <span
                  className={`text-[20px] font-bold absolute whitespace-nowrap -top-8`}
                >
                  Done
                </span>
                <circle
                  className={`${
                    step < 4 ? "bg-gray" : "bg-primary"
                  } w-[50px] text-xl rounded-full text-white h-[50px] flex items-center justify-center`}
                >
                  4
                </circle>
              </li>
            </div>
          </ul>

          <div>
            {step <= 0 ? <EmptyCart /> : ""}
            {step === 1 ? (
              <CartItems setStep={setStep} setsubtotal={setsubtotal} />
            ) : (
              ""
            )}
            {step === 2 ? (
              <PlaceOrder
                setStep={setStep}
                subtotal={subtotal}
                cart={[data?.state?.cart]}
                setMethod={setMethod}
                methd={methd}
                setOrder={setOrder}
              />
            ) : (
              ""
            )}
            {step === 3 ? (
              <Elements stripe={stripeTestPromise}>
                <PaymentMethod setStep={setStep} data={Order} />
              </Elements>
            ) : (
              ""
            )}
            {step === 4 ? <Congratulations /> : ""}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Cart;
