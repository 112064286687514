import React, { useState, useEffect } from "react";
import pending from "../../../images/pending.png";
import file from "../../../images/file.png";
import Layout from "../../ModuleComponents/Layout/Layout";
import folder from "../../../images/folder.png";
import van from "../../../images/van.png";
import check from "../../../images/check.png";
import OrderPlaced from "./OrderPlaced";
import OrderComplete from "./OrderComplete";
import Products from "../Home/Products";
import gucci from "../../../images/gucci.png";
import vougue from "../../../images/vougue.png";
import brickell from "../../../images/brickell.png";
import { getallProduct, updateStatus } from "../../../api/api";
import WalletOutlinedIcon from "@mui/icons-material/WalletOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import LocalPostOfficeOutlinedIcon from "@mui/icons-material/LocalPostOfficeOutlined";
import { useLocation } from "react-router-dom";

const Index = () => {
  let currentuser = JSON.parse(localStorage.getItem("auth_user"));
  console.log(currentuser);
  let data = useLocation();
  const [step, setStep] = useState(
    data?.state?.paymentInfo === "Pending" &&
      data?.state?.orderStatus === "Pending"
      ? 1
      : data?.state?.paymentInfo === "Paid" &&
        data?.state?.orderStatus === "Pending"
      ? 2
      : data?.state?.paymentInfo === "Paid" &&
        data?.state?.orderStatus === "Order Have Been Send to Courier"
      ? 3
      : data?.state?.orderStatus === "Delivered"
      ? 4
      : data?.state?.orderStatus === "Received"
      ? 5
      : 1
  );
  const [Best, setBest] = useState([]);
  const [UOrder, setUOrder] = useState([]);
  const [Random, setRandom] = useState();
  function getMultipleRandom(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  }

  useEffect(() => {
    brandData();
  }, []);
  const brandData = () => {
    getallProduct()
      .then((res) => {
        setBest(res.data.data);
        setRandom(getMultipleRandom(res.data.data, 4));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout>
      <div className="main-container">
        {/* <h3 className="text-gray text-2xl capitalize mb-12 py-4">
          Order History {`>`} Order Detail
        </h3> */}
        <div className="max-w-[1392px]  w-full px-5 mx-auto">
          <div className="flex items-center">
            <div className="flex-1">
              <p
                style={{
                  fontSize: 28,
                  color: "#ff6969",
                }}
              >
                {`Payment ${data?.state?.paymentInfo}`}
              </p>
            </div>
            <span className="h-[142px] w-[2px] bg-primary"></span>
            <div className="flex-[4] ">
              <ul className="flex mt-[30px] items-center sm:px-28 flex-col md:flex-row flex-wrap gap-y-10 gap-x-2">
                <div className="flex-1 w-full flex items-center gap-x-2">
                  <li className="flex flex-col cursor-pointer items-center relative">
                    <span
                      className={`text-[20px] font-bold absolute whitespace-nowrap -top-8`}
                    >
                      Order placed
                    </span>
                    <circle
                      className={`${
                        step <= 0 ? "bg-gray" : "bg-primary"
                      } w-[50px] text-xl rounded-full text-white h-[50px] flex items-center justify-center`}
                    >
                      <img src={file} alt="file" />
                    </circle>
                  </li>
                  <li
                    className={`${
                      step < 2 ? "bg-gray" : "bg-primary"
                    } flex-1 bg-primary h-[5px] `}
                  ></li>

                  <li className="flex flex-col cursor-pointer items-center relative">
                    <span
                      className={`text-[20px] font-bold absolute whitespace-nowrap -top-8`}
                    >
                      Order Paid
                    </span>
                    <circle
                      className={`${
                        step < 2 ? "bg-gray" : "bg-primary"
                      } w-[50px] text-xl rounded-full text-white h-[50px] flex items-center justify-center`}
                    >
                      {" "}
                      <WalletOutlinedIcon fontSize="large" />
                    </circle>
                  </li>
                  <li
                    className={`${
                      step < 3 ? "bg-gray" : "bg-primary"
                    } flex-1  md:block hidden  bg-primary h-[5px]`}
                  ></li>
                  <li className="flex flex-col cursor-pointer items-center relative">
                    <span
                      className={`text-[20px] font-bold absolute whitespace-nowrap -top-8`}
                    >
                      Shipment
                    </span>
                    <circle
                      className={`${
                        step < 3 ? "bg-gray" : "bg-primary"
                      } w-[50px] text-xl rounded-full text-white h-[50px] flex items-center justify-center`}
                    >
                      <LocalShippingOutlinedIcon fontSize="large" />
                    </circle>
                  </li>
                  <li
                    className={`${
                      step < 4 ? "bg-gray" : "bg-primary"
                    } flex-1 bg-primary h-[5px]`}
                  ></li>
                  <li className="flex flex-col cursor-pointer items-center relative">
                    <span
                      className={`text-[20px] font-bold absolute whitespace-nowrap -top-8`}
                    >
                      Delivered
                    </span>
                    <circle
                      className={`${
                        step < 4 ? "bg-gray" : "bg-primary"
                      } w-[50px] text-xl rounded-full text-white h-[50px] flex items-center justify-center`}
                    >
                      <LocalPostOfficeOutlinedIcon fontSize="large" />
                    </circle>
                  </li>
                  <li
                    className={`${
                      step < 5 ? "bg-gray" : "bg-primary"
                    } flex-1 bg-primary h-[5px]`}
                  ></li>

                  <li className="flex flex-col cursor-pointer items-center relative">
                    <span
                      className={`text-[20px] font-bold absolute whitespace-nowrap -top-8`}
                    >
                      Order Complete
                    </span>
                    <circle
                      onClick={() =>
                        data?.state?.orderStatus === "Delivered"
                          ? updateStatus({
                              UId: currentuser.user._id,
                              id: data?.state?._id,
                              orderStatus: "Received",
                            }).then((res) => {
                              setUOrder(res.data.order);
                              setStep(5);
                            })
                          : ""
                      }
                      className={`${
                        step < 5 ? "bg-gray" : "bg-primary"
                      } w-[50px] text-xl rounded-full text-white h-[50px] flex items-center justify-center`}
                    >
                      <CheckOutlinedIcon fontSize="large" />
                    </circle>
                  </li>
                </div>
              </ul>
            </div>
          </div>
          <div>
            {step === 1 || step === 2 || step === 3 || step === 4 ? (
              <OrderPlaced data={data?.state} />
            ) : (
              ""
            )}
            {step === 5 ? <OrderComplete data={data?.state} /> : ""}
          </div>
        </div>
      </div>
      <Products data={Random} title={`Related Products`} />
    </Layout>
  );
};

export default Index;

const cards = [
  {
    id: 1,
    title: "Gucci Bag",
    img: gucci,
    desc: "Gucci Bag with gold platted chain for your luxurious travels.",
    rating: 4,
    stock: "in stock",
    discount: "",
    price: 200,
  },
  {
    id: 2,
    title: "Gucci Bag",
    img: vougue,
    desc: "Gucci Bag with gold platted chain for your luxurious travels.",
    rating: 3,
    stock: "sold",
    discount: "",
    price: 200,
  },
  {
    id: 3,
    title: "Gucci Bag",
    img: brickell,
    desc: "Gucci Bag with gold platted chain for your luxurious travels.",
    rating: 5,
    stock: "in stock",
    discount: "20",
    price: 200,
  },
  {
    id: 4,
    title: "Gucci Bag",
    img: vougue,
    desc: "Gucci Bag with gold platted chain for your luxurious travels.",
    rating: 5,
    stock: "in stock",
    discount: "20",
    price: 200,
  },
  {
    id: 5,
    title: "Gucci Bag",
    img: gucci,
    desc: "Gucci Bag with gold platted chain for your luxurious travels.",
    rating: 5,
    stock: "in stock",
    discount: "20",
    price: 200,
  },
];
