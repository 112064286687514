import React from "react";
import { BsCartPlus } from "react-icons/bs";
import { Link } from "react-router-dom";
import Button from "../../ModuleComponents/Button";
const EmptyCart = () => {
  return (
    <div className="py-16 min-h-[939px]">
      <div className="flex flex-col justify-center items-center">
        <div className="text-[450px] text-gray mb-[50px]">
          <BsCartPlus />
        </div>
        <h3 className="mb-[80px] text-gray text-5xl text-center">
          your cart is empety
        </h3>

        <Button width={309} height={66}>
          <Link to="/">continue shipping</Link>
        </Button>
      </div>
    </div>
  );
};

export default EmptyCart;
