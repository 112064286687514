import React, { useState, useEffect, useContext } from "react";
import Card from "./Card";
import gucci from "../../images/gucci.png";
import vougue from "../../images/vougue.png";
import brickell from "../../images/brickell.png";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getallBrand } from "../../api/api";
import Filter from "./Filter";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Slider from "@mui/material/Slider";
import Pagination from "@mui/material/Pagination";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#ff6969",
    },
  },
});
let product = [];
const FilterProducts = ({ data, allData }) => {
  const [brand, setBrand] = useState();
  const [isLoading, setLoading] = useState(true);
  const [Price, setPrice] = useState([0, 100]);

  const [Rating, setRating] = useState([0, 5]);
  const [Bid, setId] = useState([]);
  const [Spe, setSpe] = useState([]);
  const [pno, setPno] = useState(1);
  useEffect(() => {
    brandData();
  }, []);
  const brandData = () => {
    getallBrand()
      .then((res) => {
        setBrand(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePrice = (e) => {
    setPrice(e);
  };
  const handleRating = (e) => {
    setRating(e);
  };

  function flattenResults(list, selections) {
    return selections?.reduce((accumulator, current) => {
      var res = list?.filter((item) => item.product_brand === current);

      console.log(res);

      return accumulator.concat(res);
    }, []);
  }
  function flattenSpeResults(list, selections) {
    return selections?.reduce((accumulator, current) => {
      var res = list?.filter((item) => item.product_position === current);

      console.log(res);

      return accumulator.concat(res);
    }, []);
  }

  Bid?.length == 0 && Spe?.length == 0 && Price[0] === 0 && Rating[0] === 0
    ? (product = data)
    : Bid?.length > 0 && Price[0] === 0 && Rating[0] === 0 && Spe?.length == 0
    ? (product = flattenResults(data, Bid))
    : Bid?.length > 0 && Price[0] != 0 && Rating[0] === 0 && Spe?.length == 0
    ? (product = data.filter(
        (e) =>
          e?.product_brand ===
            Bid?.map((e) => {
              return e;
            })[0] &&
          e?.productVariant[0]?.Price <= Price[1] &&
          e?.productVariant[0]?.Price >= Price[0]
      ))
    : Bid?.length == 0 && Spe?.length == 0 && Price != 0 && Rating != 0
    ? (product = data.filter(
        (e) =>
          e?.product_brand ===
            Bid?.map((e) => {
              return e;
            })[0] &&
          e?.productVariant[0]?.Price <= Price[1] &&
          e?.productVariant[0]?.Price >= Price[0] &&
          e?.reviews[0] <= Rating
      ))
    : Bid?.length == 0 && Spe?.length == 0 && Price != 0 && Rating === 0
    ? (product = data.filter(
        (e) =>
          e?.productVariant[0]?.Price <= Price[1] &&
          e?.productVariant[0]?.Price >= Price[0]
      ))
    : Bid?.length == 0 && Spe?.length == 0 && Price === 0 && Rating != 0
    ? (product = data.filter((e) => e?.reviews[0] <= Rating))
    : Bid?.length > 0 && Price === 0 && Rating != 0 && Spe?.length == 0
    ? (product = data.filter(
        (e) =>
          e?.product_brand ===
            Bid?.map((e) => {
              return e;
            })[0] && e?.reviews[0] <= Rating
      ))
    : Bid?.length == 0 && Spe?.length == 0 && Price === 0 && Rating != 0
    ? (product = data.filter(
        (e) =>
          e?.product_brand ===
            Bid?.map((e) => {
              return e;
            })[0] && e?.reviews[0] <= Rating
      ))
    : Bid?.length === 0 && Price[0] === 0 && Rating[0] === 0 && Spe?.length > 0
    ? (product = flattenSpeResults(data, Spe))
    : (product = data);

  // let amount = data?.reduce((a, e) => {
  //   if (e?.productVariant[0]?.Price) {
  //     a.push({
  //       value: e?.productVariant[0]?.Price,
  //       label: `$${e?.productVariant[0]?.Price} `,
  //     });
  //   }
  //   return a;
  // }, []);
  // let max = data?.reduce((a, e) => {
  //   return a > e?.productVariant[0]?.Price ? a : e?.productVariant[0]?.Price;
  // });
  // function valuetext(value) {
  //   return `${value}°C`;
  // }
  let pagedata = product;
  let pageno = 1;
  const handleSpeBrand = (e) => {
    if (Spe.filter((i) => i === e)[0] === e) {
      setSpe(Spe.filter((i) => i !== e));
    } else {
      setSpe([...Spe, e]);
    }
  };

  return (
    <>
      <div className="grid sm:grid-cols-[260px_1fr] gap-x-4 lg:gap-x-[50px]">
        <div className="sm:block hidden">
          <div className="border border-gray px-5 py-[30px]">
            <h3 className="text-gray text-2xl font-bold pb-[10px] border-b border-gray">
              Filter By
            </h3>
            <h4 className="capitalize text-primary text-xl font-bold mt-[15px] mb-6">
              brands
            </h4>
            <Filter setId={setId} Bid={Bid} />
            <div className="pb-5 border-b border-t mt-5  border-gray">
              <div className="flex items-center mt-5 gap-x-4">
                <input
                  type={`checkbox`}
                  name="checkbox"
                  value="Most Sold"
                  className="w-[22px] h-[22px]"
                  onChange={() => handleSpeBrand("special")}
                />
                <span className="text-xl capitalize">Special Offers</span>
              </div>
              <div className="flex items-center mt-5 gap-x-4">
                <input
                  type={`checkbox`}
                  name="checkbox"
                  value="Most Sold"
                  className="w-[22px] h-[22px]"
                  onChange={() => handleSpeBrand("bestseller")}
                />
                <span className="text-xl capitalize">Best Seller</span>
              </div>
              <div className="flex items-center mt-5 gap-x-4">
                <input
                  type={`checkbox`}
                  name="checkbox"
                  value="Most Sold"
                  className="w-[22px] h-[22px]"
                  onChange={() => handleSpeBrand("bestseller")}
                />
                <span className="text-xl capitalize">Womens Favourite</span>
              </div>
              <div className="flex items-center mt-5 gap-x-4">
                <input
                  type={`checkbox`}
                  name="checkbox"
                  value="Most Sold"
                  className="w-[22px] h-[22px]"
                  onChange={() => handleSpeBrand("menfav")}
                />
                <span className="text-xl capitalize">Mens Collection</span>
              </div>
            </div>
            <div className="pb-5 border-b border-gray">
              <h4 className="capitalize text-primary text-xl font-bold mt-[15px] mb-6">
                Price
              </h4>
              <Slider
                size="large"
                aria-label="Always visible"
                defaultValue={0}
                value={Price}
                valueLabelDisplay="on"
                onChange={(e) => handlePrice(e.target.value)}
                sx={{ color: "black" }}
              />
              <Typography
                sx={{ position: "relative", top: "-5px", left: "-10px" }}
              >
                ${Price[0]}
              </Typography>
              <Typography
                sx={{ position: "relative", left: "85%", marginTop: "-33px" }}
              >
                ${Price[1]}
              </Typography>
            </div>

            <div className="pb-5">
              <h4 className="capitalize text-primary text-xl font-bold mt-[15px] mb-6">
                Ratting
              </h4>
              <Slider
                size="large"
                aria-label="Always visible"
                defaultValue={0}
                value={Rating}
                max={5}
                valueLabelDisplay="on"
                onChange={(e) => handleRating(e.target.value)}
                sx={{ color: "black" }}
              />
              <Typography
                sx={{ position: "relative", top: "-5px", left: "-10px" }}
              >
                {Rating[0]}
              </Typography>
              <Typography
                sx={{ position: "relative", left: "97%", marginTop: "-33px" }}
              >
                {Rating[1]}
              </Typography>
              {/* <h4 className="mt-[15px] mb-[30px] text-primary text-xl capitalize">
                rating
              </h4> */}

              {/* <Slider
                size="large"
                aria-label="Always visible"
                defaultValue={0}
                value={Rating}
                max={5}
                valueLabelDisplay="on"
                onChange={(e) => handleRating(e.target.value)}
                sx={{ color: "black" }}
              />

              <Typography id="non-linear-slider" gutterBottom>
                Ratting: {Rating}
              </Typography> */}
            </div>
          </div>
        </div>
        {isLoading ? (
          <Stack
            sx={{ color: "grey.500", marginLeft: "45%" }}
            spacing={2}
            direction="row"
          >
            <CircularProgress color="success" />
          </Stack>
        ) : (
          <>
            <Card
              data={product.slice(pno - 1 === 0 ? 0 : (pno - 1) * 12, pno * 12)}
              co={4}
            />
          </>
        )}
      </div>
      <ThemeProvider theme={theme}>
        <Grid
          container
          direction="row"
          justifyContent="space-betwee"
          alignItems="center"
          sx={{ marginTop: 3 }}
        >
          <Grid item lg={5.8}></Grid>
          <Grid item justifyContent="center" alignContent="center" lg={4}>
            <Pagination
              count={
                pagedata.length / 12 >= 1 ? Math.ceil(pagedata.length / 12) : 1
              }
              color="primary"
              onChange={(e, value) => setPno(value)}
            />
          </Grid>
          <Grid item lg={3.2}></Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default FilterProducts;
