import React from "react";

import Button from "../../ModuleComponents/Button";
import parse from "html-react-parser";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1080,
  overflow: "scroll",
  height: 520,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const PrivacyModal = ({ open, close, data, setaPrivacy }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <button className="absolute top-8 right-8" onClick={close}>
            X
          </button> */}
          {parse(data)}
          <div className="flex w-full justify-center gap-x-[100px]  mt-[15px]">
            <Button onClick={close} secondary width={309} height={70}>
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default PrivacyModal;
