import React, { useState, useEffect } from "react";
import Layout from "../../ModuleComponents/Layout/Layout";
import OrderHistory from "./OrderHistory";
import Wishlist from "./Wishlist";
import { getallProduct, getWishList, getallOrders } from "../../../api/api";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
const Index = () => {
  const [component, setComponent] = useState(true);
  const [Best, setBest] = useState();
  const [isLoading, setLoading] = useState(true);
  const [wishlist, setWishlist] = useState([]);
  const [pending, setPending] = useState([]);
  const [AllOrder, setAllOrder] = useState([]);
  const handleComponent = () => {
    setComponent(!component);
  };
  let currentuser = JSON.parse(localStorage.getItem("auth_user"));

  useEffect(() => {
    brandData();
  }, []);
  const brandData = () => {
    getallProduct()
      .then((res) => {
        setBest(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getWishList({ id: currentuser.user._id })
      .then((res) => {
        setWishlist(res.data.wish);
      })
      .catch((err) => {
        console.log(err);
      });
    getallOrders({ id: currentuser.user._id }).then((res) => {
      setAllOrder(res.data.data);
      setPending(res.data.Pending);
      setLoading(false);
    });
  };
  console.log(pending);

  return (
    <Layout>
      <div className="py-10">
        <div className="main-container">
          <div className="flex justify-center gap-x-6 mb-[50px]">
            <h3
              onClick={handleComponent}
              className={`${
                component === true ? "text-primary border-b" : "text-gray"
              } font-bold text-[36px] capitalize max-w-max cursor-pointer pb-[12px]  border-primary`}
            >
              wishlist
            </h3>
            <h3
              onClick={handleComponent}
              className={`${
                component !== true ? "text-primary border-b" : "text-gray"
              } font-bold text-[36px] capitalize max-w-max cursor-pointer pb-[12px]  border-primary`}
            >
              order history
            </h3>
          </div>
          {isLoading ? (
            <Stack
              sx={{ color: "grey.500", marginLeft: "45%" }}
              spacing={2}
              direction="row"
            >
              <CircularProgress color="success" />
            </Stack>
          ) : component === true ? (
            <Wishlist data={wishlist} />
          ) : (
            <OrderHistory data={AllOrder} />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Index;
