import React from "react";
import img1 from "../../../images/style.jpeg";
import img2 from "../../../images/lipstick.png";
import { Link } from "react-router-dom";
import Button from "../../ModuleComponents/Button";

const TopCard = ({ data }) => {
  return (
    <div className="flex lg:flex-row flex-col">
      <div className="flex-1 relative">
        <div className="relative h-full">
          <img
            className="h-[380px] w-full object-cover "
            src={data?.feature1?.img}
            alt="img"
          />
          <div className="py-8 px-7 flex items-end absolute inset-x-0 top-0 w-full h-[280px]"></div>
        </div>
      </div>
      <div className="flex-1 relative">
        <div className="relative h-full">
          <img
            className="h-[380px] w-full object-cover "
            src={data?.feature2?.img}
            alt="img"
          />
          <div className="py-8 px-7 flex items-end absolute inset-x-0 top-0 w-full h-[280px]"></div>
        </div>
      </div>
    </div>
  );
};

export default TopCard;
