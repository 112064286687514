import React, { useState } from "react";
import { updateCart, deleteCart } from "../../../api/api";
import { Checkbox } from "../../AbstractComponents/Checkbox";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
const CartData = ({
  e,
  setCart,
  setSelected,
  setProducts,
  products,
  setTotal,
  TotalAmount,
}) => {
  const [quantity, setQuantity] = useState(e.Qty);
  const initialValue = 0;
  const [Showmore, setShowmore] = useState(false);
  let currentuser = JSON.parse(localStorage.getItem("auth_user"));
  const handleDecrement = () => {
    if (quantity > 1) {
      updateCart({
        id: e._id,
        Qty: quantity - 1,
        user_id: currentuser?.user._id,
      }).then((res) => {
        setQuantity(res.data.Qty);
        setCart(res.data.cart);
        setTotal(
          res.data.cart?.reduce((a, e) => a + e.Qty * e.price, initialValue)
        );
      });
    }
  };

  const handleIncremant = () => {
    updateCart({
      id: e._id,
      Qty: quantity + 1,
      user_id: currentuser?.user._id,
    }).then((res) => {
      setQuantity(res.data.Qty);
      setCart(res.data.cart);
      setTotal(
        res.data.cart?.reduce((a, e) => a + e.Qty * e.price, initialValue)
      );
    });
  };
  const handleDelete = () => {
    deleteCart({
      id: e._id,
      user_id: currentuser?.user._id,
    }).then((res) => {
      setCart(res.data.cart);
      window.location.reload();
    });
  };

  return (
    <div
      key={e._id}
      className="flex min-w-max items-center border-b border-gray pt-[50px] pb-[30px]"
    >
      <div className="flex-1 flex">
        <div className="flex-[2] flex gap-x-[40px] ">
          <div className="flex-1">
            <Checkbox
              style={{ marginLeft: "20px" }}
              checked={
                products.filter((i) => i._id === e._id)?.length > 0
                  ? true
                  : false
              }
              setProducts={setProducts}
              products={products}
              e={e}
              onClick={(status) => {
                setSelected(e._id ? e._id : "", status);
              }}
            />
          </div>
          <div className="flex-1">
            <img
              className="w-[150px] h-[150px]"
              src={e.product_image}
              alt="img"
            />
          </div>
          <div className="flex-[2] flex flex-col gap-y-4">
            <h3 className="text-primary capitalize font-bold text-2xl">
              {e.name}
            </h3>
            <p className="text-2xl text-primary w-[200px]">
              {Showmore
                ? e.description
                : `${e?.description?.substring(0, 35)}.... `}
            </p>
          </div>
        </div>
      </div>
      <div className="flex-1 flex">
        <div className="flex-1 flex justify-center">
          <div className="flex-1">
            <p className="text-2xl text-primary">{e.V1}</p>
            <p className="text-2xl text-primary">{e.V2}</p>
          </div>
        </div>
        <div className="flex-1 flex justify-center">
          <div className="w-[138px] border-2 border-primary h-[41px] flex items-center justify-between">
            <button
              onClick={() => handleDecrement()}
              className="flex-1 text-4xl font-bold text-primary px-5"
            >
              -
            </button>
            <div className="max-w-max">
              <input
                value={quantity}
                className="w-full focus:outline-none text-2xl justify-center flex items-center"
              ></input>
            </div>
            <button
              onClick={() => handleIncremant()}
              className="flex-1 text-4xl font-bold text-primary px-5"
            >
              +
            </button>
          </div>
        </div>
        <div className="flex-1 flex justify-center">
          <p className="text-3xl text-primary font-bold capitalize">
            {""}${(quantity * JSON.parse(e.price)).toFixed(2)}{" "}
          </p>{" "}
        </div>
        <div className="flex-1 flex justify-center">
          <DeleteForeverIcon
            fontSize="large"
            sx={{ color: "red", cursor: "pointer" }}
            onClick={() => handleDelete()}
          />
        </div>
      </div>
    </div>
  );
};

export default CartData;
