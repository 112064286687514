import React, { useState } from "react";
import { TextField, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

const currencies = [
  {
    value: "lorem",
    label: "lorem",
  },
  {
    value: "lorem",
    label: "lorem",
  },
];

const SelectField = ({
  lable,
  type,
  name,
  onChange,
  data,
  options,
  placeholder,
}) => {
  const itemOptions = options ? options : currencies;

  return (
    <>
      <Typography
        gutterBottom
        sx={{
          fontSize: "1.2rem",
          fontWeight: 300,
          color: "black",
        }}
      >
        {lable}
      </Typography>
      <TextField
        fullWidth
        type={type}
        value={data}
        select
        name={name}
        onChange={onChange}
        placeholder={placeholder}
      >
        {itemOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

export default SelectField;
