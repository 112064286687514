import React from "react";
import Button from "../../ModuleComponents/Button";

const NewPassword = ({ setComponent }) => {
  return (
    <div>
      <div className="py-[43px] ">
        <h3 className="max-w-max capitalize font-bold text-xl lg:text-[31px] text-primary ">
          New Password
        </h3>
        <div className="mt-[25px] mb-[50px] flex flex-col max-w-[440px] w-full">
          <form className="flex flex-col gap-y-[25px]">
            <input
              type={`password`}
              required
              placeholder="Current Password"
              className="px-[13px] text-primary h-[50px] w-full border-gray border focus:outline-none  py-[11px]"
            ></input>
            <input
              type={`password`}
              required
              placeholder="New Password"
              className="px-[13px] text-primary h-[50px] w-full border-gray border focus:outline-none  py-[11px]"
            ></input>
            <input
              type={`password`}
              required
              placeholder="Confirm Password"
              className="px-[13px] text-primary h-[50px] w-full border-gray border focus:outline-none  py-[11px]"
            ></input>
          </form>
        </div>

        <div className="flex flex-col">
          <Button
            onClick={() => setComponent("address")}
            type={`submit`}
            width={308}
            className={` lg:h-[70px] h-[60px]`}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
