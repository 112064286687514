import React from "react";
import { useState } from "react";
import Footer from "./Footer";
import Nav from "./Nav";
import Sidebar from "./Sidebar";
import SubMenueComponenet from "./SubMenueComponenet";
import SubNav from "./SubNav";

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [SubMenue, setSubMenue] = React.useState(false);
  const [SideDrawer, setSideDrawer] = useState("signin");

  const ToggleSidebar = (component) => {
    setSideDrawer(component);
    setIsOpen((prevState) => !prevState);
  };

  const toggleCategories = () => {
    setSubMenue((prevState) => !prevState);
  };

  return (
    <>
      <Nav ToggleSidebar={ToggleSidebar} toggleCategories={toggleCategories} />
      <SubMenueComponenet
        SubMenue={SubMenue}
        toggleCategories={toggleCategories}
      />
      <SubNav toggleCategories={toggleCategories} />
      <Sidebar
        ToggleSidebar={ToggleSidebar}
        toggleCategories={toggleCategories}
        SideDrawer={SideDrawer}
        open={isOpen}
        setIsOpen={setIsOpen}
      />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
