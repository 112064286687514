import React from "react";
import Layout from "../../ModuleComponents/Layout/Layout";
import Products from "../Home/Products";
import ProductDetail from "./ProductDetail";
import ReviewsSpecs from "./ReviewsSpecs";
import gucci from "../../../images/gucci.png";
import vougue from "../../../images/vougue.png";
import brickell from "../../../images/brickell.png";
import { useNavigate, useLocation } from "react-router-dom";
const Index = () => {
  let data = useLocation();

  return (
    <Layout>
      <ProductDetail data={data.state} />
      <ReviewsSpecs data={data.state} />
      {/* <Products data={cards} title={`Related Products`} /> */}
    </Layout>
  );
};

export default Index;
