import React, { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import Confirmation from "../../AbstractComponents/SidebarComponents/Confirmation";
import ForgottenPassword from "../../AbstractComponents/SidebarComponents/ForgottenPassword";
import MyAccount from "../../AbstractComponents/SidebarComponents/MyAccount";
import NewPassword from "../../AbstractComponents/SidebarComponents/NewPassword";
import Profile from "../../AbstractComponents/SidebarComponents/Profile";
import Registration from "../../AbstractComponents/SidebarComponents/Registration";
import SignupLogin from "../../AbstractComponents/SidebarComponents/SignupLogin";
import Verification from "../../AbstractComponents/SidebarComponents/Verification";
import Setting from "../../AbstractComponents/SidebarComponents/Setting";
import MobileMenu from "./MobileMenu";
import EzPurchaseCenter from "../../AbstractComponents/SidebarComponents/EzPurchaseCenter";

const Sidebar = ({
  open,
  SideDrawer,
  toggleCategories,
  ToggleSidebar,
  setIsOpen,
}) => {
  const [index, setIndex] = useState(0);
  const [resize, setResize] = useState();

  const handleSidebar = () => {
    ToggleSidebar(SideDrawer);
    setIndex(0);
  };

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setResize(360);
    } else {
      setResize(600);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <>
      <Drawer
        open={open}
        onClose={() => ToggleSidebar(SideDrawer)}
        direction="right"
        className={`relative overflow-y-scroll scroll-width-none`}
        size={resize}
      >
        <div className={`py-4`}>
          <div
            className={`${
              SideDrawer === "chatbot"
                ? "bg-primary p-6 fixed top-0 z-50 justify-between"
                : "bg-transparent p-7 justify-end"
            } flex items-center w-full  `}
          >
            {SideDrawer === "chatbot" && (
              <p className="text-white font-bold text-xl sm:text-2xl md:text-3xl">
                EzPurchase Help Center{" "}
              </p>
            )}
            <button
              onClick={handleSidebar}
              className="w-[25px] h-[25px] relative flex "
            >
              <span
                className={`${
                  SideDrawer === "chatbot" ? "bg-white" : "bg-primary"
                } w-[33px] absolute m-auto inset-0 h-[2px] rotate-45 `}
              ></span>
              <span
                className={`${
                  SideDrawer === "chatbot" ? "bg-white" : "bg-primary"
                } w-[33px] absolute m-auto inset-0 h-[2px] -rotate-45 `}
              ></span>
            </button>
          </div>

          {SideDrawer === "signin" && (
            <div>
              {index === 0 && <SignupLogin setIndex={setIndex} />}
              {index === 1 && (
                <MyAccount setIndex={setIndex} setOpen={handleSidebar} />
              )}
              {index === 2 && (
                <Registration setIndex={setIndex} setOpen={handleSidebar} />
              )}
              {index === 3 && <ForgottenPassword setIndex={setIndex} />}
              {index === 4 && <Verification setIndex={setIndex} />}
              {index === 5 && <NewPassword setIndex={setIndex} />}
              {index === 6 && <Confirmation setIndex={setIndex} />}
              {index === 7 && <Profile setIndex={setIndex} />}
            </div>
          )}
          {SideDrawer === "signined" && (
            <div>{index === 0 && <Profile setIndex={setIndex} />}</div>
          )}
          {SideDrawer === "account" && (
            <div>
              {index === 0 && (
                <Setting setIndex={setIndex} setOpen={handleSidebar} />
              )}
              {index === 1 && <NewPassword setIndex={setIndex} />}
              {index === 2 && <Confirmation setIndex={setIndex} />}
              {index === 7 && <Profile setIndex={setIndex} />}
            </div>
          )}
          {SideDrawer === "nav" && (
            <MobileMenu toggleCategories={toggleCategories} />
          )}

          {SideDrawer === "chatbot" && <EzPurchaseCenter />}
        </div>
      </Drawer>
    </>
  );
};

export default Sidebar;
