import React from "react";
import { Link } from "react-router-dom";
import Card from "../../AbstractComponents/Card";

const Products = ({ data, title, co }) => {
  return (
    <div className="pt-[80px] pb-10">
      <div className="main-container">
        <div className="flex justify-between items-end mb-[50px]">
          <h3 className="section-title">{title}</h3>
          <Link
            to="/products"
            className="font-bold text-xl capitalize text-[#FF6969] max-w-max pb-[12px] h-max border-b border-primary"
          >
            Shop More {`>`}
          </Link>
        </div>

        <Card data={data} co={co ? co : 3} />
      </div>
    </div>
  );
};

export default Products;
