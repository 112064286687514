import React, { useContext } from "react";
import vector1 from "../../../images/black-vector.png";
import vector2 from "../../../images/pink-vector.png";
import Button from "../../ModuleComponents/Button";

const SignupLogin = ({ setIndex }) => {
  return (
    <div>
      <div className="relative">
        <img className="top-0 relative" src={vector1} alt="vector" />
        <img className="-top-3 lg:-top-5 relative" src={vector2} alt="vector" />
      </div>

      <div className="py-[50px] px-4 mx-auto max-w-[308px]">
        <h3 className="text-center capitalize font-bold text-2xl text-primary pb-[25px] border-b border-[#999999]">
          Create your Account for Luxurious Items.
        </h3>

        <div className="mt-[114px] flex flex-col gap-y-[50px] w-full">
          <Button
            className={`h-[60px] lg:h-[70px]`}
            onClick={() => setIndex(1)}
          >
            Sign In
          </Button>
          <Button
            onClick={() => setIndex(2)}
            secondary
            className={`h-[60px] lg:h-[70px]`}
          >
            Create Account
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SignupLogin;
