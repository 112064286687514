import React from "react";
import Layout from "../../ModuleComponents/Layout/Layout";
import Cart from "./Cart";

const Index = () => {
  return (
    <Layout>
      <Cart />
    </Layout>
  );
};

export default Index;
