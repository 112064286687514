import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import vector1 from "../../../images/black-vector.png";
import vector2 from "../../../images/pink-vector.png";
import Button from "../../ModuleComponents/Button";
import PrivacyModal from "../PrivacyPolicyModals/PrivacyModal";
import TermsServiceModal from "../PrivacyPolicyModals/TermsServiceModal";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { register, getallSettings } from "../../../api/api";
import { toast } from "react-toastify";
import ToastContext from "../../../context/ToastContext";
const Registration = ({ setIndex, setOpen }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const toastOptions = useContext(ToastContext);
  let navigate = useNavigate();

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  function openModal2() {
    setModal2(true);
  }
  function closeModal2() {
    setModal2(false);
  }

  const [Terms, setTerms] = useState("");
  const [Privacy, setPrivacy] = useState("");
  const [aTerm, setaTerm] = useState(false);
  const [aPrivacy, setaPrivacy] = useState(false);
  console.log(aPrivacy);
  useEffect(() => {
    getsetting();
  }, []);

  const getsetting = () => {
    getallSettings().then((res) => {
      setTerms(res.data.data.termandPrivacy?.terms_conditions);
      setPrivacy(res.data.data.termandPrivacy?.privacy_policy);
    });
  };
  return (
    <div>
      <div className="relative">
        <img className="top-0 relative" src={vector1} alt="vector" />
        <img className="-top-3 lg:-top-5 relative" src={vector2} alt="vector" />
      </div>

      <div className="py-5 px-4 lg:py-[50px]">
        <h3 className="text-center mx-auto max-w-[308px] capitalize font-bold text-2xl text-primary pb-4 lg:pb-[25px] border-b border-[#999999]">
          Registration
        </h3>
        <Formik
          initialValues={{
            name: "",
            email: "",
            password: "",
            userphone: "",
            compairpassword: "",
            terms: false,
            privacy: false,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Name is required"),
            terms: Yup.bool().oneOf([true], "Must be checked"),
            privacy: Yup.bool().oneOf([true], "Must be checked"),
            userphone: Yup.string().required("Phone Number is required"),
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
            password: Yup.string().max(255).required("Password is required"),
            compairpassword: Yup.string().oneOf(
              [Yup.ref("password"), null],
              "Passwords must match"
            ),
          })}
          onSubmit={(values) => {
            register(values)
              .then((res) => {
                setOpen();
                toast.success(res.data.message, toastOptions);
              })
              .catch((err) => {
                if (err.response.status === 401) {
                  toast.error(err.response.data.error, toastOptions);
                }
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <div className="mt-8 lg:mt-[56px] mb-6 lg:mb-10 flex flex-col max-w-[440px] mx-auto w-full">
              <form
                className="flex flex-col gap-y-4  lg:gap-y-[25px]"
                onSubmit={handleSubmit}
              >
                <>
                  <>
                    <input
                      type={`name`}
                      name="name"
                      placeholder="Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      className="px-[13px] text-primary h-[50px] w-full border-gray border focus:outline-none  py-[11px]"
                    ></input>
                    {touched.name && errors.name && (
                      <text
                        style={{ color: "red" }}
                        error
                        id="standard-weight-helper-text-name-login"
                      >
                        {errors.name}
                      </text>
                    )}
                    <input
                      type={`email`}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      placeholder="Email"
                      className="px-[13px] text-primary h-[50px] w-full border-gray border focus:outline-none  py-[11px]"
                    ></input>
                    {touched.email && errors.email && (
                      <text
                        style={{ color: "red" }}
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.email}
                      </text>
                    )}
                    <input
                      type={"text"}
                      name="userphone"
                      placeholder="Phone Number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.userphone}
                      className="px-[13px] text-primary h-[50px] w-full border-gray border focus:outline-none  py-[11px]"
                    ></input>
                    {touched.userphone && errors.userphone && (
                      <text
                        style={{ color: "red" }}
                        error
                        id="standard-weight-helper-text-userphone-login"
                      >
                        {errors.userphone}
                      </text>
                    )}
                    <input
                      type={`password`}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      name="password"
                      placeholder="Password"
                      className="px-[13px] text-primary h-[50px] w-full border-gray border focus:outline-none  py-[11px]"
                    ></input>
                    {touched.password && errors.password && (
                      <text
                        style={{ color: "red" }}
                        error
                        id="standard-weight-helper-text-password-login"
                      >
                        {errors.password}
                      </text>
                    )}
                    <input
                      type={`password`}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.compairpassword}
                      name="compairpassword"
                      placeholder="Confirm Password"
                      className="px-[13px] text-primary h-[50px] w-full border-gray border focus:outline-none  py-[11px]"
                    ></input>
                    {touched.compairpassword && errors.compairpassword && (
                      <text
                        style={{ color: "red" }}
                        error
                        id="standard-weight-helper-text-compairpassword-login"
                      >
                        {errors.compairpassword}
                      </text>
                    )}
                  </>
                  <div className="mb-8 lg:mb-[50px] flex flex-col gap-y-5 justify-center">
                    <div className="flex justify-center items-center gap-x-[10px]">
                      <input
                        className="lg:w-5 w-4 h-4 lg:h-5 bg-primary text-white"
                        type={`checkbox`}
                        name="terms"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.terms}
                        checked={values.terms}
                      ></input>

                      <p className="text-center text-primary capitalize font-light text-base lg:text-xl ">
                        I agree with the
                        <Link
                          onClick={openModal2}
                          className="text-[#FF6969] capitalize"
                          to="/"
                        >
                          <span> Terms & Conditions</span>
                        </Link>
                      </p>
                    </div>
                    <div className="flex justify-center items-center gap-x-[10px]">
                      {touched.terms && errors.terms && (
                        <text
                          style={{ color: "red" }}
                          error
                          id="standard-weight-helper-text-terms-login"
                        >
                          {errors.terms}
                        </text>
                      )}
                    </div>
                    <div className="flex justify-center items-center gap-x-[10px]">
                      <input
                        className="lg:w-5 w-4 h-4 lg:h-5 bg-primary text-white"
                        type={`checkbox`}
                        name="privacy"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.privacy}
                        checked={values.privacy}
                      ></input>

                      <p className="text-center text-primary  capitalize font-light text-base lg:text-xl ">
                        I agree with the
                        <Link
                          onClick={openModal}
                          className="text-[#FF6969] capitalize"
                          to="/"
                        >
                          <span> Privacy & Policy</span>
                        </Link>
                      </p>
                    </div>
                    <div className="flex justify-center items-center gap-x-[10px]">
                      {touched.privacy && errors.privacy && (
                        <text
                          style={{ color: "red" }}
                          error
                          id="standard-weight-helper-text-privacy-login"
                        >
                          {errors.privacy}
                        </text>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <Button
                      type={`submit`}
                      width={308}
                      className={`mb-[30px] mx-auto h-[60px] lg:h-[70px]`}
                    >
                      Register
                    </Button>
                  </div>
                </>
              </form>
            </div>
          )}
        </Formik>
        <div className="flex flex-col">
          <p className="text-center text-primary capitalize font-light text-lg lg:text-xl ">
            If you don’t have registered with us,
            <br />
            <Link
              onClick={() => setIndex(1)}
              className="text-red-500 capitalize"
              to="/"
            >
              sign in
            </Link>{" "}
            with us.
          </p>
        </div>
      </div>
      <PrivacyModal
        setaPrivacy={setaPrivacy}
        data={Privacy}
        open={modalIsOpen}
        close={closeModal}
      />
      <TermsServiceModal
        setaTerm={setaTerm}
        data={Terms}
        open={modal2}
        close={closeModal2}
      />
    </div>
  );
};

export default Registration;
