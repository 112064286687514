import React from "react";
import { useState, useContext, useEffect } from "react";
import img33 from "../../../images/product/img1.png";
import Button from "../../ModuleComponents/Button";
import Products from "../Home/Products";
import Modal from "react-modal";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  saveAddress,
  getAlladdress,
  getAllCart,
  saveOrder,
  getallSettings,
  getallProduct,
} from "../../../api/api";
import { toast } from "react-toastify";
import ToastContext from "../../../context/ToastContext";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import SelectField from "../../../Components/Staff_fields/SelectField";
import { MyImage } from "./MyImage";

let city = [{ id: "Singapore", name: "Singapore" }];
let initialValue = 0;
const PlaceOrder = ({
  setStep,
  subtotal,
  methd,
  setMethod,
  setOrder,
  cart,
  CuponData,
  Discount,
}) => {
  const [shipping, setShipping] = useState(true);
  const [payment, setPayment] = useState();
  const [stripe, setstripe] = useState();
  const [paypal, setpaypal] = useState();
  console.log(CuponData);
  useEffect(() => {
    getsetting();
  }, []);

  const getsetting = () => {
    getallSettings().then((res) => {
      setstripe(res.data.data.Payment?.stripe);
      setpaypal(res.data.data.Payment?.paypal);
      setPayment(res.data.data);
    });
  };

  const handleShipping = () => {
    setShipping(!shipping);
  };
  const [paymentMethod, setPaymentMethod] = useState(methd);
  const handlePaymetn = () => {
    setPaymentMethod(!paymentMethod);
    setMethod(!paymentMethod);
  };
  const toastOptions = useContext(ToastContext);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [Best, setBest] = useState([]);
  const [address, setAddress] = React.useState([]);
  const [check, setCheckout] = useState(cart);
  const [shippingAddress, setshippingAddress] = React.useState(null);
  const [placeOrder, setPlaceorder] = useState(null);
  const [Showmore, setShowmore] = useState(false);
  let currentuser = JSON.parse(localStorage.getItem("auth_user"));
  const [Random, setRandom] = useState();
  function getMultipleRandom(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  }
  useEffect(() => {
    brandData();
  }, []);
  const brandData = () => {
    getAlladdress({ id: currentuser.user._id })
      .then((res) => {
        setAddress(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // getAllCart({ id: currentuser.user._id })
    //   .then((res) => {
    //     setCheckout(res.data.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    getallProduct()
      .then((res) => {
        setBest(res.data.data);
        setRandom(getMultipleRandom(res.data.data, 4));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  let Quantity = check?.reduce((a, c) => a + c.Qty, initialValue);
  let Wg = check.reduce((a, c) => a + c.weight * c.Qty, initialValue);
  const PlaceOrder = () => {
    if (shippingAddress === null) {
    } else {
      if (paymentMethod === false) {
        saveOrder({
          shipping_address: shippingAddress,
          paymentMethod: paymentMethod,
          items: check,
          user_id: currentuser.user._id,
          orderStatus: "Pending",
          shippingPrice: 0,
          subTotal: subtotal,
          totalPrice: (parseFloat(subtotal) - parseInt(Discount)).toFixed(2),
          paymentInfo: "Pending",
          CuponData: CuponData._id,
        }).then((res) => {
          setStep(4);
          toast.success(res.data.message, toastOptions);
        });
      } else {
        setOrder({
          shipping_address: shippingAddress,
          paymentMethod: paymentMethod,
          items: check,
          user_id: currentuser.user._id,
          orderStatus: "Pending",
          shippingPrice: 0,
          subTotal: subtotal,
          totalPrice: (parseFloat(subtotal) - parseInt(Discount)).toFixed(2),
          paymentInfo: "Paid",
          CuponData: CuponData._id,
        });
        setStep(3);
      }
    }
  };

  return (
    <div className="pt-20 pb-12">
      <div className="flex lg:flex-row flex-col">
        <div className="flex-[2] lg:pr-7">
          <div className="lg:max-w-[806px]  w-full">
            <h3 className="text-primary font-bold text-3xl capitalize mb-[25px]">
              Home Delivery
            </h3>
            <div>
              <SelectField
                name="address"
                onChange={(e) => setshippingAddress(e.target.value)}
                options={address?.map((name) => ({
                  label: name.address,
                  value: name._id,
                }))}
              />
              {shippingAddress === null ? (
                <>
                  {" "}
                  <text
                    style={{ color: "red" }}
                    id="standard-weight-helper-text-name-login"
                  >
                    Please Select Shipping information
                  </text>
                </>
              ) : (
                ""
              )}

              <Button height={60} className={`mt-4`} onClick={openModal}>
                add address
              </Button>
            </div>

            <div className="mt-[50px]">
              <div className="flex gap-x-[25px] pb-4 border-b border-gray">
                <span className="text-primary text-2xl font-bold">CN</span>
                <span className="text-gray text-2xl font-bold">
                  {Quantity} Item| {Wg} kg
                </span>
              </div>
            </div>

            <div className="mt-[30px] pb-[50px] ">
              <h3 className="text-primary font-bold text-3xl mb-[20px] capitalize">
                International Shipping
              </h3>
              <div className="flex md:flex-row flex-col gap-y-8 gap-x-8">
                <div className="flex-1">
                  <div
                    onClick={handleShipping}
                    className={`${
                      shipping
                        ? "text-[#FB6D75] border-[#FB6D75]"
                        : "text-gray border-gray"
                    } lg:max-w-[353px] cursor-pointer  w-full min-h-[147px] p-5 border `}
                  >
                    <p className=" text-2xl font-bold mb-3">$0</p>
                    <p className=" text-2xl font-bold mb-4 capitalize">sea</p>
                    <p className=" text-2xl font-bold capitalize">
                      Arrive in US: 17/12/19/12
                    </p>
                  </div>
                </div>
                <div className="flex-1 flex md:justify-end">
                  <div
                    onClick={handleShipping}
                    className={`${
                      !shipping
                        ? "text-[#FB6D75] border-[#FB6D75]"
                        : "text-gray border-gray"
                    } lg:max-w-[353px] cursor-pointer  w-full min-h-[147px] p-5 border `}
                  >
                    <p className=" text-2xl font-bold mb-3">$0</p>
                    <p className=" text-2xl font-bold mb-4 capitalize">
                      Economy Air
                    </p>
                    <p className=" text-2xl font-bold capitalize">
                      Arrive in US: 17/12/19/12
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-[30px]">
            {check?.map((e) => (
              <div className="flex md:flex-row flex-col gap-y-8  border-t border-gray pt-[30px] lg:mr-8  pb-[30px]">
                <div className="flex-1 flex gap-x-[40px] ">
                  <div className="flex-1">
                    <MyImage
                      dimension={105}
                      borderRadius="0%"
                      src={e?.product_image}
                    />
                  </div>
                  <div className="flex-[2] flex flex-col gap-y-4">
                    <h3 className="text-primary capitalize font-bold text-2xl">
                      {e.name}
                    </h3>
                    <p className="text-2xl text-primary">
                      {e?.description?.substring(0, 25)}....
                    </p>
                  </div>{" "}
                </div>

                <div className="flex-1 flex">
                  <div className="flex-1 flex justify-center">
                    <div className="flex flex-col gap-y-4 items-center">
                      <p className="text-2xl text-gray font-bold capitalize">
                        {e.V1.split(":")[0]}
                      </p>
                      <p className="text-2xl text-primary capitalize">
                        {" "}
                        {e.V1.split(":")[1]}
                      </p>
                    </div>
                  </div>

                  <div className="flex-1 flex justify-center">
                    <div className="flex flex-col gap-y-4 items-center">
                      <p className="text-2xl text-gray font-bold capitalize">
                        {e.V2.split(":")[0]}
                      </p>
                      <p className="text-2xl text-primary capitalize">
                        {e.V2.split(":")[1]}
                      </p>
                    </div>
                  </div>
                  <div className="flex-1 flex justify-center">
                    <div className="flex flex-col gap-y-4 items-center">
                      <p className="text-2xl text-gray font-bold capitalize">
                        Quantity
                      </p>
                      <p className="text-2xl text-primary capitalize">
                        {e.Qty}
                      </p>
                    </div>
                  </div>
                  <div className="flex-1 flex justify-center">
                    <div className="flex flex-col gap-y-4 items-center">
                      <p className="text-2xl text-gray font-bold capitalize">
                        Price
                      </p>
                      <p className="text-2xl text-primary capitalize">
                        $ {e.price}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex-1 flex flex-col gap-y-[30px]">
          <div className="py-[20px] px-[25px] border border-primary">
            <h3 className="text-primary mb-5 font-bold text-3xl capitalize ">
              Payment Method
            </h3>
            {paypal === true || stripe === true ? (
              <div
                onClick={handlePaymetn}
                className="flex cursor-pointer mb-[22px] "
              >
                <div
                  className={`${
                    paymentMethod
                      ? "bg-[#FB6D75] border-[#FB6D75]"
                      : "bg-transparent border-primary"
                  }  min-w-[30px] h-[30px] border mr-[10px] rounded-full`}
                ></div>
                <div>
                  <h4 className="text-2xl text-primary mb-1 capitalize">
                    Online Payment{" "}
                  </h4>
                  {/* <p className="text-xl font-light text-primary">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
                </p> */}
                </div>
              </div>
            ) : (
              ""
            )}

            <div onClick={handlePaymetn} className="flex cursor-pointer">
              <div
                className={`${
                  !paymentMethod
                    ? "bg-[#FB6D75] border-[#FB6D75]"
                    : "bg-transparent border-primary"
                }  min-w-[30px] h-[30px] mr-[10px] border rounded-full`}
              ></div>
              <div>
                <h4 className="text-2xl text-primary mb-1 capitalize">
                  Cash on Delivery
                </h4>
                {/* <p className="text-xl font-light text-primary">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
                </p> */}
              </div>
            </div>
          </div>

          <div className="py-[20px] px-[25px] border border-primary">
            <h3 className="text-primary mb-5 font-bold text-3xl capitalize ">
              Order Summary
            </h3>

            <div className="flex  flex-col  pb-5 border-b border-primary">
              <div className="flex justify-between mb-5">
                <p className="text-2xl text-primary capitalize">
                  Item(s) Subtotal
                </p>
                <p className="text-2xl text-primary capitalize">
                  ${parseFloat(subtotal).toFixed(2)}
                </p>
              </div>

              <div className="flex justify-between">
                <p className="text-2xl text-primary capitalize">Discount</p>
                <p className="text-2xl text-primary capitalize">
                  ${!Discount ? 0 : Discount}
                </p>
              </div>
            </div>

            <div className="py-5 border-b border-primary">
              <div className="flex justify-between">
                <p className="text-2xl text-primary capitalize">Voucher</p>
                <p className="text-2xl text-gray capitalize">
                  {CuponData !== "" ? CuponData?.code : "unavailable"}
                </p>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-between">
                <p className="text-2xl text-primary capitalize font-bold">
                  total
                </p>
                <p className="text-2xl text-primary font-bold capitalize">
                  ${" "}
                  {(
                    parseFloat(subtotal) - parseInt(!Discount ? 0 : Discount)
                  ).toFixed(2)}
                </p>
              </div>
            </div>
          </div>
          <Button onClick={() => PlaceOrder()} width={309} height={66}>
            place order
          </Button>
        </div>
      </div>
      <Products data={Random} title={`Related Products`} />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <button className="absolute top-8 right-8" onClick={closeModal}>
          X
        </button>
        <h3 className="text-primary w-full font-bold text-4xl text-center">
          Add New Address
        </h3>
        <Formik
          initialValues={{
            name: "",
            phone: "",
            state: "Singapore",
            city: "",
            address: "",
            postalcode: "",
            user_id: currentuser.user._id,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required("Name is required"),
            phone: Yup.string().max(255).required("Number is required"),
            state: Yup.string().max(255).required("State is required"),
            city: Yup.string().max(255).required("City is required"),
            address: Yup.string().max(255).required("Address is required"),
            postalcode: Yup.string()
              .max(255)
              .required("Postal Code is required"),
          })}
          onSubmit={(values) => {
            saveAddress(values)
              .then((res) => {
                toast.success(res.data.message, toastOptions);
                setAddress(res.data.data);
                closeModal();
              })
              .catch((err) => {
                if (err.response?.status === 401) {
                  toast.error(err.response.data.error, toastOptions);
                  closeModal();
                }
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div className="flex flex-col items-center">
                <div className="w-full mt-10 md:mt-20 md:flex-row flex-col gap-y-5 md:gap-y-12 flex gap-x-[100px]">
                  <div className="flex-1 flex flex-col gap-y-5 md:gap-y-11">
                    <div className="flex flex-col">
                      <label
                        className="text-3xl font-bold text-primary mb-5"
                        htmlFor="name"
                      >
                        *Name
                      </label>
                      <input
                        name="name"
                        placeholder="Enter your name..."
                        className="focus:outline-none border-2 border-gray h-[50px] py-[11px] px-[11px]"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      ></input>
                      {touched.name && errors.name && (
                        <text
                          style={{ color: "red" }}
                          error
                          id="standard-weight-helper-text-name-login"
                        >
                          {errors.name}
                        </text>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <label
                        className="text-3xl font-bold text-primary mb-5"
                        htmlFor="name"
                      >
                        *State
                      </label>

                      <SelectField
                        type="text"
                        name="state"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        data={values.state}
                        options={city?.map((name) => ({
                          label: name.name,
                          value: name.id,
                        }))}
                      />
                      {touched.state && errors.state && (
                        <text
                          style={{ color: "red" }}
                          error
                          id="standard-weight-helper-text-state-login"
                        >
                          {errors.state}
                        </text>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <label
                        className="text-3xl font-bold text-primary mb-5"
                        htmlFor="name"
                      >
                        *Address{" "}
                      </label>
                      <input
                        name="address"
                        placeholder="Enter your address..."
                        className="focus:outline-none border-2 border-gray h-[50px] py-[11px] px-[11px]"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                      ></input>
                      {touched.address && errors.address && (
                        <text
                          style={{ color: "red" }}
                          error
                          id="standard-weight-helper-text-address-login"
                        >
                          {errors.address}
                        </text>
                      )}
                    </div>
                  </div>
                  <div className="flex-1">
                    {" "}
                    <div className="flex-1 flex flex-col gap-y-5 md:gap-y-11">
                      <div className="flex flex-col">
                        <label
                          className="text-3xl font-bold text-primary mb-5"
                          htmlFor="phone"
                        >
                          *Phone Number
                        </label>
                        <input
                          name="phone"
                          placeholder="+94"
                          className="focus:outline-none border-2 border-gray h-[50px] py-[11px] px-[11px]"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                        ></input>
                        {touched.phone && errors.phone && (
                          <text
                            style={{ color: "red" }}
                            error
                            id="standard-weight-helper-text-phone-login"
                          >
                            {errors.phone}
                          </text>
                        )}
                      </div>
                      <div className="flex flex-col">
                        <label
                          className="text-3xl font-bold text-primary mb-5"
                          htmlFor="name"
                        >
                          *City{" "}
                        </label>
                        <input
                          name="city"
                          placeholder="City ..."
                          className="focus:outline-none border-2 border-gray h-[50px] py-[11px] px-[11px]"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.city}
                        ></input>

                        {touched.city && errors.city && (
                          <text
                            style={{ color: "red" }}
                            error
                            id="standard-weight-helper-text-city-login"
                          >
                            {errors.city}
                          </text>
                        )}
                      </div>
                      <div className="flex flex-col">
                        <label
                          className="text-3xl font-bold text-primary mb-5"
                          htmlFor="name"
                        >
                          *Postal Code{" "}
                        </label>
                        <input
                          name="postalcode"
                          placeholder="Zip Code"
                          className="focus:outline-none border-2 border-gray h-[50px] py-[11px] px-[11px]"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.postalcode}
                        ></input>
                        {touched.postalcode && errors.postalcode && (
                          <text
                            style={{ color: "red" }}
                            error
                            id="standard-weight-helper-text-postalcode-login"
                          >
                            {errors.postalcode}
                          </text>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  className={` mt-10 md:mt-20`}
                  type="Submit"
                  width={309}
                  height={70}
                >
                  Save & Use
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default PlaceOrder;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    maxWidth: "1510px",
    width: "100%",
    padding: "50px 80px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.7)",
  },
};
