import React from "react";
import { useState, useEffect, useContext } from "react";
import CartItems from "./CartItems";
import Congratulations from "./Congratulations";
import EmptyCart from "./EmptyCart";
import PaymentMethod from "./PaymentMethod";
import PlaceOrder from "./PlaceOrder";
import { getAllCart } from "../../../api/api";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const PUBLIC_KEY =
  "pk_test_51KhiBwB8UuxyKopHuaiYkJDFwmTqBWhR0HUtBroaxGDQqeVFMzMGIJbdUoLV4Gmfxb1dQu3cAo8CaQVRPQuqlMZM00ur2dTZ9K";
const stripeTestPromise = loadStripe(PUBLIC_KEY);
const Cart = () => {
  const [step, setStep] = useState(1);
  const [filterCart, setFilterCart] = useState([]);
  const [subtotal, setsubtotal] = useState(0);
  const [methd, setMethod] = useState(false);
  const [Order, setOrder] = useState(false);
  const [CuponData, setCuponData] = useState("");
  const [Discount, setDiscount] = useState(0);
  return (
    <div className="py-10">
      <div className="max-w-[1392px] w-full px-5 mx-auto">
        <ul className="flex mt-[30px] items-center px-28 flex-col md:flex-row flex-wrap gap-y-10 gap-x-2">
          <div className="flex-1 w-full flex items-center gap-x-2">
            <li className="flex flex-col cursor-pointer items-center relative">
              <span
                className={`text-[20px] font-bold absolute whitespace-nowrap -top-8`}
              >
                Details
              </span>
              <circle
                onClick={step !== 4 ? () => setStep(1) : ""}
                className={`${
                  step <= 0 ? "bg-gray" : "bg-primary"
                } w-[50px] text-xl rounded-full text-white h-[50px] flex items-center justify-center`}
              >
                1
              </circle>
            </li>
            <li
              className={`${
                step < 2 ? "bg-gray" : "bg-primary"
              } flex-1 bg-primary h-[5px]`}
            ></li>

            <li className="flex flex-col cursor-pointer items-center relative">
              <span
                className={`text-[20px] font-bold absolute whitespace-nowrap -top-8`}
              >
                Place Order
              </span>
              <circle
                onClick={step !== 4 ? () => setStep(2) : ""}
                className={`${
                  step < 2 ? "bg-gray" : "bg-primary"
                } w-[50px] text-xl rounded-full text-white h-[50px] flex items-center justify-center`}
              >
                2
              </circle>
            </li>
          </div>
          <li
            className={`${
              step < 3 ? "bg-gray" : "bg-primary"
            } flex-1  md:block hidden  bg-primary h-[5px]`}
          ></li>
          <div className="flex-1 w-full flex gap-x-2 items-center">
            <li className="flex flex-col cursor-pointer items-center relative">
              <span
                className={`text-[20px] font-bold absolute whitespace-nowrap -top-8`}
              >
                Payment Method
              </span>
              <circle
                onClick={step !== 4 ? () => setStep(3) : ""}
                className={`${
                  step < 3 ? "bg-gray" : "bg-primary"
                } w-[50px] text-xl rounded-full text-white h-[50px] flex items-center justify-center`}
              >
                3
              </circle>
            </li>
            <li
              className={`${
                step < 4 ? "bg-gray" : "bg-primary"
              } flex-1 bg-primary h-[5px]`}
            ></li>

            <li className="flex flex-col cursor-pointer items-center relative">
              <span
                className={`text-[20px] font-bold absolute whitespace-nowrap -top-8`}
              >
                Done
              </span>
              <circle
                className={`${
                  step < 4 ? "bg-gray" : "bg-primary"
                } w-[50px] text-xl rounded-full text-white h-[50px] flex items-center justify-center`}
              >
                4
              </circle>
            </li>
          </div>
        </ul>

        <div>
          {step <= 0 ? <EmptyCart /> : ""}
          {step === 1 ? (
            <CartItems
              setStep={setStep}
              setFilterCart={setFilterCart}
              setsubtotal={setsubtotal}
              setCuponData={setCuponData}
              CuponData={CuponData}
              setDiscount={setDiscount}
              Discount={Discount}
            />
          ) : (
            ""
          )}
          {step === 2 ? (
            <PlaceOrder
              setStep={setStep}
              subtotal={subtotal}
              cart={filterCart}
              setMethod={setMethod}
              methd={methd}
              setOrder={setOrder}
              CuponData={CuponData}
              Discount={Discount}
            />
          ) : (
            ""
          )}
          {step === 3 ? (
            <Elements stripe={stripeTestPromise}>
              <PaymentMethod setStep={setStep} data={Order} />{" "}
            </Elements>
          ) : (
            ""
          )}
          {step === 4 ? <Congratulations /> : ""}
        </div>
      </div>
    </div>
  );
};

export default Cart;
