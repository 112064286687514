import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../images/logo.svg";
import favs from "../../../images/favs.svg";
import user from "../../../images/user.svg";
import { BiChevronDown } from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
let currentuser = JSON.parse(localStorage.getItem("auth_user"));
const Nav = ({ toggleCategories, ToggleSidebar }) => {
  let navigate = useNavigate();
  let data = JSON.parse(localStorage.getItem("auth_user"))
    ? JSON.parse(localStorage.getItem("auth_user"))
    : { user: "null" };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {" "}
      <div className=" bg-[#FDFDFD] py-2 ">
        <div className="max-w-[1840px] px-5 mx-auto">
          <div className="flex items-center gap-x-8 justify-between ">
            <div>
              <Link to={`/`}>
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <ul className="hidden lg:flex gap-x-9 xl:gap-x-[45px] flex-wrap items-center min-h-[80px]">
              {links.map((li) => {
                const { id, text, path } = li;
                return (
                  <li className="flex gap-x-2 items-center " key={id}>
                    <Link className="text-xl font-bold text-primary" to={path}>
                      {text}
                    </Link>
                    {id === 4 && (
                      <span
                        className="text-xl cursor-pointer"
                        onClick={toggleCategories}
                      >
                        <BiChevronDown />
                      </span>
                    )}
                  </li>
                );
              })}
            </ul>
            <div className="flex gap-x-[14px] sm:gap-x-[30px] items-center">
              {data.user != "null" ? (
                <>
                  {" "}
                  <Link
                    onClick={() => ToggleSidebar("signined")}
                    to="/"
                    className="text-xl text-primary font-normal"
                  >
                    {data.user.name}
                  </Link>
                </>
              ) : (
                <>
                  {" "}
                  <Link
                    onClick={() => ToggleSidebar("signin")}
                    to="/"
                    className="text-xl text-primary font-normal"
                  >
                    Sign in
                  </Link>
                </>
              )}

              <Link
                to={data?.user != "null" ? "/wishlist" : "/"}
                onClick={() =>
                  data?.user != "null" ? "" : ToggleSidebar("signin")
                }
              >
                <img src={favs} alt="favs" />
              </Link>
              {data?.user != "null" ? (
                <button onClick={handleClick}>
                  <img src={user} alt="user" />
                </button>
              ) : (
                ""
              )}
              <button
                className="lg:hidden block text-2xl text-primary"
                onClick={() => ToggleSidebar("nav")}
              >
                <GiHamburgerMenu />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem>
          <Avatar /> Profile
        </MenuItem> */}
        <MenuItem onClick={() => ToggleSidebar("account")}>
          <Avatar sizes="small" /> My account
        </MenuItem>
        <Divider />
        {/* <MenuItem>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem> */}
        {/* <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem> */}
        <MenuItem onClick={() => localStorage.clear()}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default Nav;

const links = [
  { id: 1, text: "Home", path: "/" },
  { id: 3, text: "New Arrivals", path: "/newArrival" },
  { id: 4, text: "Products", path: "/products" },
  { id: 5, text: "About", path: "/vouchers" },
];
