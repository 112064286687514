import React from "react";
//          Importing Colors

//          Importing Icons
import { RiCheckboxBlankLine, RiCheckboxLine } from "react-icons/ri";

export const Checkbox = ({ style, checked, onClick, dimensions }) => {
  const [isChecked, setChecked] = React.useState(true);
  React.useEffect(() => {
    if (checked) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [checked]);
  const toggleCheckbox = (status) => {
    setChecked(status);
    if (onClick) {
      onClick(status);
    }
  };
  const Style = {
    checkbox: {
      color: isChecked ? "#FF6969" : "#272727",
      width: `${dimensions ? dimensions : 25}px`,
      height: `${dimensions ? dimensions : 25}px`,
      cursor: "pointer",
    },
  };
  return (
    <>
      {isChecked ? (
        <RiCheckboxLine
          style={
            style ? { ...Style.checkbox, ...style } : { ...Style.checkbox }
          }
          onClick={() => {
            toggleCheckbox(false);
          }}
        />
      ) : (
        <RiCheckboxBlankLine
          style={
            style ? { ...Style.checkbox, ...style } : { ...Style.checkbox }
          }
          onClick={() => {
            toggleCheckbox(true);
          }}
        />
      )}
    </>
  );
};
