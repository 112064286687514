import React, { useState, useEffect } from "react";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { BiChevronDown } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import AutoComplete from "../AutoComplete";
import Badge from "@mui/material/Badge";
import { getallCategories, getAllCart } from "../../../api/api";
const badgeStyle = {
  "& .MuiBadge-badge": {
    color: "white",
    backgroundColor: "#ff6969",
  },
};

const SubNav = ({ toggleCategories }) => {
  let currentuser = JSON.parse(localStorage.getItem("auth_user"));
  const [cart, setCart] = useState([]);
  let navigate = useNavigate();
  const [data, setCategores] = useState();
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    brandData();
  }, []);
  const brandData = () => {
    getallCategories()
      .then((res) => {
        setCategores(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    getAllCart({ id: currentuser?.user._id })
      .then((res) => {
        setCart(res.data.data);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="bg-white min-h-[130px] py-2 w-full flex">
      <div className="m-auto px-4 flex items-center gap-x-[50px]">
        <div className="flex relative flex-col gap-y-[25px]">
          <div className="flex gap-x-4">
            <div
              className="text-xl mt-2 font-bold flex h-max items-center gap-x-[2]"
              onClick={toggleCategories}
              style={{ cursor: "pointer" }}
            >
              Categories
              <span>
                <BiChevronDown />
              </span>
            </div>
            <div>
              <div className="border-2 flex items-center border-gray h-[47px]  md:max-w-[685px] w-full pl-7">
                <AutoComplete
                  placeholder={`Search on ezpurchase...`}
                  data={autoCompleteData}
                />
                <Link className="text-[23px] text-gray max-w-max px-7" to="/">
                  <BsSearch />
                </Link>
              </div>

              <ul className="hidden lg:flex mt-[25px] items-center flex-wrap gap-y-4 gap-x-[37px] ">
                {data?.slice(0, 4)?.map((li) => {
                  return (
                    <li key={li._id}>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/products")}
                        className="text-xl font-normal text-primary"
                      >
                        {li.name}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>

            <Link className="h-max mt-2" to={`/cart`}>
              <span className="mr-2  text-primary text-3xl flex items-center">
                {cart?.length > 0 ? (
                  <Badge badgeContent={cart?.length} sx={badgeStyle}>
                    <HiOutlineShoppingBag />
                  </Badge>
                ) : (
                  <HiOutlineShoppingBag />
                )}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubNav;

const link = [
  { id: 1, text: "Beauty & Fashion", url: "Beauty & Fashion" },
  { id: 2, text: "Cosmetics", url: "Cosmetics" },
  { id: 3, text: "Entertainment", url: "Entertainment" },
  { id: 4, text: "Media & Technology", url: "Media & Technology" },
];
const autoCompleteData = [
  "Asparagus",
  "Aaaaragus",
  "Aasdparagus",
  "Aafggparagus",
  "Aafhwparagus",
  "Aaadtwparagus",
  "Beetroot",
  "Broccoli",
  "Cabbage",
  "Carrot",
  "Cauliflower",
  "Celery",
  "Corn",
  "Eggplant",
  "Lettuce",
  "Mushroom",
  "Onion",
  "Parsnip",
  "Pea",
  "Potato",
  "Pumpkin",
  "Radish",
  "Spinach",
  "Tomato",
  "Turnip",
];
