import React, { useState, useContext, useEffect } from "react";
import Button from "../../ModuleComponents/Button";
import master from "../../../images/mastercard.png";
import visa from "../../../images/visa.png";
import paypal from "../../../images/paypal.png";
import simage from "../../../images/stripe.webp";
import Products from "../Home/Products";
import {
  paypal_payment,
  stripe_payment,
  getallProduct,
  getallSettings,
} from "../../../api/api";
import { toast } from "react-toastify";
import ToastContext from "../../../context/ToastContext";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { PayPalButton } from "react-paypal-button-v2";
const card_options = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#ffff",
      fontWeight: 500,
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":webkit-autofill": { color: "#fce883" },
      ":placeholder": { color: "#87bbfd" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
};

const PaymentMethod = ({ setStep, data }) => {
  const [Stripe, setstripe] = useState();
  const [Paypal, setpaypal] = useState();

  useEffect(() => {
    getsetting();
  }, []);

  const getsetting = () => {
    getallSettings().then((res) => {
      setstripe(res.data.data.Payment?.stripe);
      setpaypal(res.data.data.Payment?.paypal);
    });
  };
  const toastOptions = useContext(ToastContext);
  const [success, setSuccess] = useState(false);
  const [sdkReady, setsdkReady] = useState(false);
  const [Best, setBest] = useState([]);
  const stripe = useStripe();
  const elements = useElements();
  let navigate = useNavigate();
  const [Random, setRandom] = useState();
  function getMultipleRandom(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  }
  const handleSubmitStripe = async (e) => {
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (!error) {
      try {
        const { id } = paymentMethod;
        stripe_payment({ order: e, p_id: id }).then((res) => {
          toast.success(res.data.message, toastOptions);
          setStep(4);
        });
      } catch (error) {}
    }
  };
  useEffect(() => {
    brandData();
  }, []);
  const brandData = () => {
    getallProduct()
      .then((res) => {
        setBest(res.data.data);
        setRandom(getMultipleRandom(res.data.data, 4));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [type, setType] = useState("");
  const handlepayment = (e) => {
    if (type === "paypal") {
      paypal_payment({
        shipping_address: data.shipping_address,
        paymentMethod: data.paymentMethod,
        items: data.items,
        user_id: data.user_id,
        orderStatus: data.orderStatus,
        shippingPrice: data.shippingPrice,
        subTotal: data.subtotal,
        totalPrice: data.totalPrice,
        paymentInfo: data.paymentInfo,
        payerId: e?.payer.payer_id,
        paymentId: e?.id,
        paymentUrl: e?.links[0]?.href,
        CuponData: data.CuponData,
      }).then((res) => {
        toast.success(res.data.message, toastOptions);
        setStep(4);
      });
    } else if (type === "stripe") {
      handleSubmitStripe({
        shipping_address: data.shipping_address,
        paymentMethod: data.paymentMethod,
        items: data.items,
        user_id: data.user_id,
        orderStatus: data.orderStatus,
        shippingPrice: data.shippingPrice,
        subTotal: data.subtotal,
        totalPrice: data.totalPrice,
        paymentInfo: data.paymentInfo,
        CuponData: data.CuponData,
      });
    }
  };
  const payment_Method = [
    { id: 1, img: simage, name: "stripe", active: Stripe },
    { id: 2, img: paypal, name: "paypal", active: Paypal },
  ];
  return (
    <div className="pt-20 pb-12">
      <div className="flex lg:flex-row gap-y-10 flex-col">
        <div className="flex-[2] lg:pr-7">
          <div className="lg:max-w-[806px]  w-full">
            <h3 className="text-primary font-bold text-3xl capitalize mb-[25px]">
              Pay via Credit Card/Debit Card
            </h3>
            <div className="flex gap-x-[30px] mb-8">
              {payment_Method.map(({ id, img, name, active }) => {
                return (
                  <>
                    {active === true ? (
                      <div key={id} className="flex gap-x-2 items-center">
                        <input
                          name="payment"
                          className="w-6 h-6"
                          type={`radio`}
                          onChange={() => setType(name)}
                        />
                        <span>
                          <img
                            src={img}
                            style={img === simage ? { width: "120px" } : {}}
                            alt=""
                          />
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
            </div>

            {type === "stripe" ? (
              <>
                {" "}
                {!success ? (
                  <form>
                    <div className="mt-[50px]">
                      <CardElement />
                    </div>
                  </form>
                ) : (
                  ""
                )}{" "}
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="flex-1 flex flex-col gap-y-[30px]">
          <div className="py-[20px] px-[25px] border border-primary">
            <h3 className="text-primary mb-5 font-bold text-3xl capitalize ">
              Order Summary
            </h3>

            <div className="flex  flex-col  pb-5 border-b border-primary">
              {/* <div className="flex justify-between mb-5">
                <p className="text-2xl text-primary capitalize">Order ID</p>
                <p className="text-2xl text-primary capitalize">PMM#434DAS</p>
              </div> */}

              <div className="flex justify-between">
                <p className="text-2xl text-primary capitalize">
                  Payment Method:
                </p>
                <p className="text-2xl text-primary capitalize">
                  {type === "" ? "Online" : type}
                </p>
              </div>
            </div>

            <div className="py-5 border-b border-primary">
              <div className="flex justify-between">
                <p className="text-2xl text-primary capitalize">Voucher</p>
                <p className="text-2xl text-gray capitalize">unavailable</p>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-between">
                <p className="text-2xl text-primary capitalize font-bold">
                  total
                </p>
                <p className="text-2xl text-primary font-bold capitalize">
                  ${data?.totalPrice}
                </p>
              </div>
            </div>
          </div>
          <p className="text-xl text-primary">
            By Clicking to Pay Securely I agree to these{" "}
            <span className="text-red mx-[5px]">Terms</span>and
            <span className="text-red mx-[5px]">Conditions</span>
          </p>
          {type === "paypal" ? (
            <PayPalButton amount={data?.totalPrice} onSuccess={handlepayment} />
          ) : (
            <Button width={309} height={66} onClick={() => handlepayment()}>
              Pay Securely
            </Button>
          )}
        </div>
      </div>
      <Products data={Random} title={`Related Products`} />
    </div>
  );
};

export default PaymentMethod;
