import React, { useState, useEffect, useContext } from "react";
import Button from "../../ModuleComponents/Button";
import { getAllCart, getallProduct, verifyCuppon } from "../../../api/api";
import Products from "../Home/Products";
import CircularProgress from "@mui/material/CircularProgress";
import { Checkbox } from "../../AbstractComponents/Checkbox";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import ToastContext from "../../../context/ToastContext";
import CartData from "./CartData";
import { useNavigate } from "react-router-dom";
const CartItems = ({
  setStep,
  setsubtotal,
  setFilterCart,
  setCuponData,
  CuponData,
  Discount,
  setDiscount,
}) => {
  let currentuser = JSON.parse(localStorage.getItem("auth_user"));
  const [cart, setCart] = useState([]);
  let navigate = useNavigate();
  const initialValue = 0;
  const [Best, setBest] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [products, setProducts] = useState([]);
  const [Random, setRandom] = useState();
  const [amount, setAmount] = useState();
  const [copun, setCopun] = useState("");

  const toastOptions = useContext(ToastContext);
  function getMultipleRandom(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  }
  useEffect(() => {
    brandData();
  }, []);
  const brandData = () => {
    getAllCart({ id: currentuser?.user._id })
      .then((res) => {
        setCart(res.data.data);
        setProducts(res.data.data);
        setAmount(
          res.data.data?.reduce((a, e) => a + e.Qty * e.price, initialValue)
        );
      })
      .catch((err) => {
        console.log(err);
      });
    getallProduct()
      .then((res) => {
        setBest(res.data.data);
        setRandom(getMultipleRandom(res.data.data, 3));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const selectAllProducts = (s) => {
    s === false ? setProducts([]) : setProducts(cart);
  };

  const selectSingleOrder = (id, s) => {
    s === false
      ? setProducts(products.filter((e) => e._id !== id))
      : setProducts([...products, cart?.filter((e) => e._id === id)[0]]);
  };
  const handleCupon = (e) => {
    verifyCuppon({ code: e })
      .then((res) => {
        setCuponData(res.data.data);
        toast.success(res.data.message, toastOptions);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          toast.error(err.response?.data?.errors, toastOptions);
        }
      });
  };

  // Stylesheet
  const formWidth = 500;
  const Style = {
    formContainer: {
      maxWidth: `${formWidth}px`,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    buttonsContainer: {
      display: "flex",
      alignItems: "center",
      flexFlow: "row wrap",
      alignContent: "space-between",
      lineBreak: "anywhere",
    },
    button: {
      padding: "8px 0px",
      maxWidth: "160px",
      marginRight: "5px",
      marginTop: "5px",
    },
    selectedText: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "27px",
      color: "#999999",
      marginRight: "10px",
    },
  };
  return (
    <>
      <div className="py-16">
        <div className="">
          <div className="overflow-x-scroll">
            <div className="min-w-max">
              <div className="flex items-center min-w-max border-y border-gray py-3">
                <div className="flex-1 flex">
                  <div className="flex-[2] flex gap-x-[40px] ">
                    <div className="flex-1">
                      <Checkbox
                        style={{ marginLeft: "20px" }}
                        onClick={(status) => {
                          selectAllProducts(status);
                        }}
                        checked={products.length === cart.length ? true : false}
                      />
                    </div>
                    <div className="flex-1">
                      <p className="text-gray text-xl capitalize">item</p>
                    </div>
                    <div className="flex-[2]">
                      <p className="text-gray text-xl capitalize">detail</p>
                    </div>{" "}
                  </div>
                </div>
                <div className="flex-1 flex">
                  <div className="flex-1 flex justify-center">
                    <p
                      className="text-xl text-gray capitalize"
                      style={{ position: "relative", left: "-40px" }}
                    >
                      {" "}
                      Variant{" "}
                    </p>{" "}
                  </div>
                  <div className="flex-1 flex justify-center">
                    <p className="text-xl text-gray capitalize"> quantity </p>{" "}
                  </div>
                  <div className="flex-1 flex justify-center">
                    <p className="text-xl text-gray capitalize"> price </p>{" "}
                  </div>
                  <div className="flex-1 flex justify-center">
                    <p className="text-xl text-gray capitalize"> Action </p>{" "}
                  </div>
                </div>
              </div>
              {isLoading ? (
                <Stack
                  sx={{ color: "grey.500", marginLeft: "45%" }}
                  spacing={2}
                  direction="row"
                >
                  <CircularProgress color="success" />
                </Stack>
              ) : (
                <>
                  {cart?.map((e) => (
                    <CartData
                      setSelected={(OID, status) => {
                        selectSingleOrder(OID, status);
                      }}
                      e={e}
                      products={products}
                      setProducts={setProducts}
                      setTotal={setAmount}
                      TotalAmount={amount}
                      setCart={setCart}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="flex sm:flex-row flex-col gap-y-8  pt-8">
          <div className="flex flex-col gap-y-[80px] items-center sm:items-start flex-1">
            <div className="flex gap-x-10">
              <p className="text-gray capitalize text-2xl">Total Discount : </p>
              <p className="text-primary font-bold text-4xl">
                {" "}
                ${" "}
                {CuponData !== ""
                  ? CuponData?.type === "Fixed"
                    ? JSON.parse(CuponData.discount)?.toFixed(2)
                    : (amount * JSON.parse(CuponData.discount / 100))?.toFixed(
                        2
                      )
                  : "0"}
              </p>
            </div>
            <Button
              secondary
              height={66}
              width={265}
              onClick={() => navigate("/products")}
            >
              Continue Shopping
            </Button>
          </div>

          <div className="flex flex-col gap-y-[80px] flex-1 items-center sm:items-end">
            <div className="flex gap-x-10">
              <p className="text-gray capitalize text-2xl">Total Price : </p>
              <p className="text-primary font-bold text-4xl">
                {" "}
                $
                {CuponData !== ""
                  ? CuponData?.type === "Fixed"
                    ? (amount - JSON.parse(CuponData.discount)).toFixed(2)
                    : (
                        amount -
                        amount * JSON.parse(CuponData.discount / 100)
                      ).toFixed(2)
                  : amount?.toFixed(2)}
              </p>
            </div>
            {products.length > 0 ? (
              <Button
                onClick={() => (
                  setsubtotal(amount?.toFixed(2)),
                  setDiscount(
                    CuponData !== ""
                      ? CuponData?.type === "Fixed"
                        ? JSON.parse(CuponData.discount)?.toFixed(2)
                        : (
                            amount * JSON.parse(CuponData.discount / 100)
                          )?.toFixed(2)
                      : "0"
                  ),
                  setStep(2),
                  setFilterCart(products)
                )}
                height={66}
                width={309}
              >
                Check Out
              </Button>
            ) : (
              <Button height={66} width={309}>
                Check Out
              </Button>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-y-[50px] py-[50px] mt-[100px]">
          <input
            placeholder="Enter Voucher Code"
            className="text-2xl focus:outline-none text-gray px-3 border-2 flex items-center border-gray max-w-[806px] h-[50px]"
            onChange={(e) => setCopun(e.target.value)}
          />
          <div className="flex gap-x-4 sm:flex-row flex-col gap-y-8">
            <div className="flex-1 flex justify-center sm:justify-start">
              <Button
                width={309}
                height={66}
                onClick={() => handleCupon(copun)}
              >
                apply
              </Button>
            </div>
            <div className="flex-1 flex justify-center sm:justify-end">
              {" "}
              {/* <Button secondary width={309} height={66}>
              Get A COUPON!
            </Button> */}
            </div>
          </div>
        </div>
      </div>

      <Products data={Random} title={`Related Products`} co={4} />
    </>
  );
};

export default CartItems;
