import { Route, Routes } from "react-router-dom";
import "./styles/global.css";
import "./assets/FontFamily/Sansation-Regular.woff2";
import Index from "./Components/Views/Home";
import Product from "./Components/Views/ProductDetails";
import HelpCenter from "./Components/Views/HelpCenter";
import Vouchers from "./Components/Views/Vouchers";
import Cart from "./Components/Views/Cart";
import Payment from "./Components/Views/Cart/Payment";
import OrderWishlist from "./Components/Views/WishlistOrderHistory";
import OrderDetails from "./Components/Views/OrderDetails";
import Settings from "./Components/Views/settings";
import "react-toastify/dist/ReactToastify.min.css";
import CategorybyId from "./Components/Views/CategoryProduct";
import { ToastContainer } from "react-toastify";
import ToastContext from "./context/ToastContext";
import ProductList from "./Components/Views/Products";
import NewArrival from "./Components/Views/NewArrival";
const toastOptions = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

function App() {
  return (
    <ToastContext.Provider value={toastOptions}>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/productList/:id" element={<ProductList />} />
        <Route path="/categorybyId/:id" element={<CategorybyId />} />
        <Route path="/products" element={<ProductList />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/newArrival" element={<NewArrival />} />
        <Route path="/vouchers" element={<Vouchers />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/HelpCenter" element={<HelpCenter />} />
        <Route path="/wishlist" element={<OrderWishlist />} />
        <Route path="/order-details" element={<OrderDetails />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </ToastContext.Provider>
  );
}

export default App;
