import React from "react";

const Button = ({
  width,
  height,
  children,
  secondary,
  onClick,
  className,
  type,
  fontSize,
  fontWeight,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      style={{
        maxWidth: width,
        height: height,
        fontSize: fontSize,
        fontWeight: fontWeight,
      }}
      className={`${
        secondary ? "bg-transparent text-primary " : "bg-primary text-white"
      } ${className} w-full capitalize inline-flex text-2xl font-medium border-2 border-primary px-2 justify-center items-center`}
    >
      {children}
    </button>
  );
};

export default Button;
