import React, { useState, useEffect } from "react";
import Button from "../ModuleComponents/Button";
import { HiOutlineShoppingBag } from "react-icons/hi";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineStar,
  AiTwotoneStar,
} from "react-icons/ai";
import Rating from "react-rating";
import { Link } from "react-router-dom";
import { addcart, addWishList, getWishList } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import ProductDetail from "../Views/ProductDetails/ProductDetail";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
const ProductCard = ({ data, co }) => {
  let navigate = useNavigate();
  const [wishlist, setWishlist] = useState([]);
  const [Showmore, setShowmore] = useState(false);
  let currentuser = JSON.parse(localStorage.getItem("auth_user"));
  useEffect(() => {
    brandData();
  }, []);
  const brandData = () => {
    getWishList({ id: currentuser?.user._id })
      .then((res) => {
        setWishlist(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addtocard = (e) => {
    addcart(e).then((res) => {
      if (res.data.status === true) {
        navigate("/cart");
      }
    });
  };
  const handleWishlist = (e) => {
    if (currentuser?.user) {
      addWishList({ id: e, user_id: currentuser?.user._id }).then((res) => {
        setWishlist(res.data.data);
      });
    }
  };

  return (
    <Grid container spacing={4}>
      {data?.map((e) => {
        let arr = [];

        e?.reviews?.map((e) => arr.push(e.review));

        const average = arr?.reduce((a, b) => a + b, 0) / arr?.length;
        return (
          <Grid item lg={co ? co : 3} xl={co ? co : 2.6}>
            <Card sx={{ minWidth: 350, minHeight: 540, maxHeight: 650 }}>
              <div className="relative">
                <div style={{ cursor: "pointer" }}>
                  <CardMedia
                    component="img"
                    onClick={() => navigate(`/product/${e._id}`, { state: e })}
                    sx={{ height: 340, objectFit: "unset" }}
                    image={e?.product_image[0].img}
                    alt="green iguana"
                  />
                </div>
                <div className="flex gap-x-1 gap-y-2 items-center absolute top-[25px] w-full justify-between bg-transparent">
                  <div className="bg-white bg-opacity-30 pl-[24px] pr-[12px]">
                    <h4 className="text-xl py-[2px] text-primary font-bold">
                      {e?.name}
                    </h4>
                  </div>
                  <div
                    onClick={() => handleWishlist(e._id)}
                    style={{ cursor: "pointer" }}
                    className="w-[47px] text-3xl text-gray flex items-center justify-center h-[38px]  mr-4 px-2 bg-white bg-opacity-30"
                  >
                    {wishlist?.find((a) => a.productId === e._id) ? (
                      <AiFillHeart style={{ color: "red" }} />
                    ) : (
                      <AiOutlineHeart style={{ color: "black" }} />
                    )}
                  </div>
                </div>

                <div className="absolute right-2 bottom-2">
                  {e?.productVariant[0]?.Stock === "0" ? (
                    <div className="w-[93px] h-[93px] rounded-full bg-[#FF6969] flex justify-center items-center">
                      <div className="text-white flex flex-col items-center">
                        <span className="text-2xl font-bold">sold</span>
                        <span className="text-lg font-normal">out</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      {e?.productVariant[0]?.Discount !== "0" ? (
                        <div className="w-[93px] h-[93px] rounded-full bg-primary  flex justify-center items-center">
                          <div className="text-white flex flex-col items-center">
                            <span className="text-2xl font-bold">
                              {e?.productVariant[0]?.Discount}%
                            </span>
                            <span className="text-lg  font-normal">off</span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              </div>

              <CardContent>
                <div className="pt-[20px] flex flex-col ">
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ marginBottom: 2, maxHeight: 53, minHeight: 53 }}
                  >
                    {console.log(e?.description)}
                    {e?.description?.substring(0, 40)}.....
                  </Typography>
                  <div className="flex justify-between items-center">
                    <div>
                      <Rating
                        initialRating={average}
                        readonly
                        emptySymbol={
                          <div className="text-2xl 2xl:text-3xl">
                            <AiOutlineStar />{" "}
                          </div>
                        }
                        fullSymbol={
                          <div className="text-2xl 2xl:text-3xl text-[#FFB801]">
                            <AiTwotoneStar />
                          </div>
                        }
                      />
                    </div>
                    <div className="text-primary text-3xl font-bold">
                      $
                      {e?.productVariant[0]?.Price.startsWith("$") === true
                        ? e?.productVariant[0]?.Price.replace("$", "")
                        : e?.productVariant[0]?.Price}
                    </div>
                  </div>

                  <Button
                    className={`h-[47px] mt-[30px]`}
                    fontWeight={`bold`}
                    secondary
                    onClick={
                      currentuser?.user
                        ? () => {
                            addtocard({
                              id: e?._id,
                              namd: e?.name,
                              des: e?.description,
                              qty: 1,
                              weight: e?.productVariant[0]?.weight,
                              img: e?.product_image[0].img,
                              price:
                                e?.productVariant[0]?.Price.startsWith("$") ===
                                true
                                  ? e?.productVariant[0]?.Price.replace("$", "")
                                  : e?.productVariant[0]?.Price,
                              v1: `${e?.productVariant[0]?.V1}: ${e?.productVariant[0]?.V1Tag}`,
                              v2: `${e?.productVariant[0]?.V2}: ${e?.productVariant[0]?.V2Tag}`,
                              user_id: currentuser.user._id,
                            });
                          }
                        : ""
                    }
                  >
                    <span className="mr-2 text-primary text-2xl">
                      <HiOutlineShoppingBag />
                    </span>
                    add to cart
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ProductCard;
