import React from "react";
import { BiChevronDown } from "react-icons/bi";
import { Link } from "react-router-dom";
import logo from "../../../images/logo.svg";

const MobileMenu = ({ toggleCategories }) => {
  return (
    <div className="flex flex-col items-center gap-y-8">
      <div>
        <Link to={`/`}>
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <ul className="flex flex-col gap-y-4 xl:gap-y-[45px] items-center">
        {links.map((li) => {
          const { id, text, path } = li;
          return (
            <li className="flex gap-y-2 items-center" key={id}>
              <Link className="text-xl font-bold text-primary" to={path}>
                {text}
              </Link>
              {id === 4 && (
                <span
                  className="text-xl cursor-pointer"
                  onClick={toggleCategories}
                >
                  <BiChevronDown />
                </span>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MobileMenu;

const links = [
  { id: 1, text: "Home", path: "/" },
  { id: 2, text: "Categories", path: "/categories" },
  { id: 3, text: "New Arrivals", path: "/new-arrivals" },
  { id: 4, text: "Products", path: "/product" },
  { id: 5, text: "About", path: "/vouchers" },
  { id: 6, text: "Beauty & Fashion", path: "Beauty & Fashion" },
  { id: 7, text: "Cosmetics", path: "Cosmetics" },
  { id: 8, text: "Entertainment", path: "Entertainment" },
  { id: 9, text: "Media & Technology", path: "Media & Technology" },
];
